import React, { MouseEvent } from 'react';
import { Grid, TextField, Typography, Button, Theme, useMediaQuery } from '@mui/material';
import { LocalUser, UserValidation } from './Account';
import { makeStyles } from '@mui/styles';
import { useAuth } from 'providers/auth';
import AccountImage from './AccountImage';

const useStyles = makeStyles({
  actions: {
    marginTop: 30,
    '& button': {
      marginTop: 20,
    },
    '& a': {
      fontWeight: 'normal',
    },
  },
  disconnectButton: {
    width: '100%',
    height: 45,
  },
});

type AccountFormProps = {
  localUser: LocalUser;
  handleChange(index: string, value: any): void;
  handleResetEmail(): void;
  validation: UserValidation;
};

const AccountForm: React.FC<AccountFormProps> = ({ localUser, handleChange, handleResetEmail, validation }) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { logout } = useAuth();
  const classes = useStyles();

  function handleDisconnectClick() {
    logout();
  }

  function handleResetEmailClick(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    handleResetEmail();
  }

  return (
    <>
      <AccountImage handleChange={handleChange} localUser={localUser} />
      <Grid width={smDown ? '100%' : '33.33%'} item xs={12} xl={4} lg={4} md={6}>
        <TextField
          error={!!validation.email}
          helperText={validation.email}
          label="E-mail"
          value={localUser.email}
          disabled
          fullWidth
          margin="normal"
        />
        <TextField
          error={!!validation.name}
          helperText={validation.name}
          label="Nome"
          value={localUser.name}
          fullWidth
          margin="normal"
          autoFocus
          onChange={e => handleChange('name', e.target.value)}
        />
        <div className={classes.actions}>
          <a href="/" onClick={handleResetEmailClick}>
            Alterar minha senha
          </a>
          <Typography variant="body2" color="textSecondary">
            Será enviado uma mensagem de e-mail com opção para alterar a senha
          </Typography>
          <Button
            style={{ backgroundColor: '#f44336', color: 'white' }}
            className={classes.disconnectButton}
            onClick={handleDisconnectClick}
          >
            Desconectar
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default AccountForm;
