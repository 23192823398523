import React from 'react';
import { ListItem, Typography } from '@mui/material';
import { BlancaLuzInstallment } from 'types/blancaLuzInstallment';
import { history } from 'services/history';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  content: {
    display: 'grid',
    rowGap: '7px',
    marginTop: 10,
    '& > p': {
      display: 'grid',
      gap: '0px',
      gridTemplateColumns: '120px 1fr',
    },
  },
});

type BlancaLuzInstallmentItemModuleProps = {
  installment: BlancaLuzInstallment;
};

const BlancaLuzInstallmentItemModule: React.FC<BlancaLuzInstallmentItemModuleProps> = ({ installment }) => {
  const classes = styles();

  return (
    <ListItem
      className={classes.container}
      button
      onClick={() => history.push(`/blanca-luz-installment/${installment.id}`)}
    >
      <Typography variant="caption" color="primary">
        Parcela nº {installment.id}
      </Typography>
      <div className={classes.content}>
        <Typography>
          <span>Juros</span>
          <span>{installment.formattedFee}</span>
        </Typography>
        <Typography>
          <span>IOF Diário</span>
          <span>{installment.formattedDailyIof}</span>
        </Typography>
        <Typography>
          <span>IOF Adicional</span>
          <span>{installment.formattedAdditionalIof}</span>
        </Typography>
        <Typography>
          <span>Fator</span>
          <span>{installment.factor}</span>
        </Typography>
        <Typography>
          <span>Vencimento</span>
          <span>{installment.due_days}</span>
        </Typography>
      </div>
    </ListItem>
  );
};

export default BlancaLuzInstallmentItemModule;
