import React from 'react';
import List from 'components/list/List';
import BlancaLuzInstallmentItemModule from './BlancaLuzInstallmentItemModule';
import { BlancaLuzInstallment } from 'types/blancaLuzInstallment';

type BlancaLuzInstallmentListModuleProps = {
  installments: BlancaLuzInstallment[];
};

const BlancaLuzInstallmentListModule: React.FC<BlancaLuzInstallmentListModuleProps> = ({ installments }) => {
  return (
    <List>
      {installments.map(installment => (
        <BlancaLuzInstallmentItemModule key={installment.id} installment={installment} />
      ))}
    </List>
  );
};

export default BlancaLuzInstallmentListModule;
