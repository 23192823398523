import React from 'react';
import { Typography } from '@mui/material';
import { userTableTamplate } from '../userTableTemplate';
import { AdminUser } from 'types/admin-user';

type UserItemProps = {
  user: AdminUser;
};

const UserItem: React.FC<UserItemProps> = ({ user }) => {
  return (
    <>
      {userTableTamplate.map(item => (
        <div key={item.id}>
          <Typography variant="body2">{user[item.id]}</Typography>
        </div>
      ))}
    </>
  );
};

export default UserItem;
