import React from 'react';
import { IconButton, Tooltip, Theme } from '@mui/material';
import { Add, FilterList } from '@mui/icons-material';
import { history } from 'services/history';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));
interface WaitingLoanActionsProps {
  openDialog(): void;
}

const WaitingLoanActions: React.FC<WaitingLoanActionsProps> = ({ openDialog }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Tooltip title="Filtrar">
        <IconButton onClick={openDialog} color="inherit">
          <FilterList />
        </IconButton>
      </Tooltip>

      <Tooltip title="Adicionar">
        <IconButton color="inherit" onClick={() => history.push('/blanca-luz-loan/new')}>
          <Add />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default WaitingLoanActions;
