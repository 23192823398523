import { SettingsActionsType, SETTINGS_CHANGE, SettingsIndexes, SET_SETTINGS } from './types';
import { SiteSetting } from 'types/siteSetting';

export function settingsChange(index: SettingsIndexes, value: any): SettingsActionsType {
  return {
    type: SETTINGS_CHANGE,
    index,
    value,
  };
}

export function setSettings(settings: SiteSetting): SettingsActionsType {
  return {
    type: SET_SETTINGS,
    settings,
  };
}
