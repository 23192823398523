export const USER_CHANGE = 'USER_CHANGE';
export const SET_USER = 'SET_USER';

export interface User {
  name: string;
  email: string;
  password?: string;
  password_confirmation?: string;
}

type UserChangeAction = {
  type: typeof USER_CHANGE;
  value: string;
  index: string;
};

type SetUserAction = {
  type: typeof SET_USER;
  user: User;
};

export type UserActionsType = UserChangeAction | SetUserAction;
