import React from 'react';
import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';

const Home: React.FC = () => {
  return (
    <>
      <Appbar title="Início" />
      <PageHeader title="Início" />
    </>
  );
};

export default Home;
