import React from 'react';
import { Image } from 'types/image';
import { makeStyles } from '@mui/styles';
import { CheckCircle } from '@mui/icons-material';
import { useDialog } from 'components/dialogs/Dialog';
import { alpha, List, ListItemButton, Theme, Typography } from '@mui/material';

const widthW = 150;

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    width: widthW,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  list: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${widthW}px, 1fr))`,
    gap: '5px',
  },
  listItem: {
    border: '2px solid transparent',
    borderRadius: 0,
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selected: {
    borderRadius: 0,
    border: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageWrapper: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
  icon: {
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  noImages: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type ImageSelectorListProps = {
  images: Image[];
  handleSetSelectedImage(image: Image, handleClose: () => void): void;
  selectedImageId?: number;
};

const ImageSelectorList: React.FC<ImageSelectorListProps> = ({ images, handleSetSelectedImage, selectedImageId }) => {
  const classes = useStyles();
  const { handleClose } = useDialog();

  function handleClick(image: Image) {
    handleSetSelectedImage(image, handleClose);
  }

  return (
    <>
      {images.length > 0 ? (
        <List className={classes.list} disablePadding>
          {images.map(image => (
            <ListItemButton
              key={image.id}
              disableGutters
              className={image.id === selectedImageId ? classes.selected : classes.listItem}
              onClick={() => handleClick(image)}
            >
              <div className={classes.container}>
                {image.id === selectedImageId && (
                  <div className={classes.imageWrapper}>
                    <CheckCircle className={classes.icon} color={'primary'} />
                  </div>
                )}
                <img src={image.imageUrl} alt={`Imagem ${image.id}`} className={classes.image} />
              </div>
            </ListItemButton>
          ))}
        </List>
      ) : (
        <div className={classes.noImages}>
          <Typography color="textSecondary">Sem imagens</Typography>
        </div>
      )}
    </>
  );
};

export default ImageSelectorList;
