import { FormControlLabel, Switch, TextField, styled } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Customer } from 'types/customer';
import { CustomerValidation } from './validation/customerValidation';
import { useCustomer } from './hooks/useCustomer';
import CnpjInput from 'components/masked-input/CnpjInput';
import PostalCodeInput from 'components/masked-input/PostalCodeInput';
import MaskedInput from 'react-text-mask';

const Container = styled('div')({
  display: 'flex',
  maxWidth: 600,
  flexDirection: 'column',
  marginTop: 30,
  '& .two-columns': {
    display: 'flex',
    gap: 20,
    '& > div': {
      flex: 3,
    },
    '& > div:last-child': {
      flex: 1,
    },
  },
});

interface CustomerFormProps {
  customer: Customer | null;
  validation: CustomerValidation;
}

const CustomerForm: React.FC<CustomerFormProps> = ({ customer, validation }) => {
  const { handleCustomerChange } = useCustomer();

  const inputs = {
    'customer.company_name': useRef<HTMLInputElement>(null),
    'customer.document_number': useRef<MaskedInput>(null),
    'customer.trade_name': useRef<HTMLInputElement>(null),
    'customer.phone': useRef<HTMLInputElement>(null),
    'customer.postal_code': useRef<MaskedInput>(null),
    'customer.street': useRef<HTMLInputElement>(null),
    'customer.street_number': useRef<HTMLInputElement>(null),
    'customer.complement': useRef<HTMLInputElement>(null),
    'customer.neighborhood': useRef<HTMLInputElement>(null),
    'customer.city': useRef<HTMLInputElement>(null),
    'customer.state': useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    if (key === 'customer.document_number') {
      inputs['customer.document_number'].current?.inputElement.focus();
      return;
    }

    if (key === 'customer.postal_code') {
      inputs['customer.postal_code'].current?.inputElement.focus();
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line
  return (
    <Container>
      <FormControlLabel
        label="Pertence ao Simples Nacional"
        control={
          <Switch
            color="primary"
            onChange={event => handleCustomerChange('is_simples_nacional', event.target.checked)}
            checked={customer?.customer.is_simples_nacional}
          />
        }
      />

      <TextField
        label="CNPJ"
        placeholder="Informe o CNPJ"
        margin="normal"
        autoFocus
        fullWidth
        helperText={validation['customer.document_number']}
        error={!!validation['customer.document_number']}
        value={customer?.customer.document_number}
        onChange={event => handleCustomerChange('document_number', event.target.value)}
        inputRef={inputs['customer.document_number']}
        InputProps={{
          inputComponent: CnpjInput as any,
        }}
      />

      <TextField
        label="Razão social"
        placeholder="Informe a razão social"
        margin="normal"
        autoFocus
        fullWidth
        helperText={validation['customer.company_name']}
        error={!!validation['customer.company_name']}
        value={customer?.customer.company_name}
        onChange={event => handleCustomerChange('company_name', event.target.value)}
        inputRef={inputs['customer.company_name']}
      />

      <TextField
        label="Nome fantasia"
        placeholder="Informe o nome fantasia"
        margin="normal"
        autoFocus
        fullWidth
        helperText={validation['customer.trade_name']}
        error={!!validation['customer.trade_name']}
        value={customer?.customer.trade_name}
        onChange={event => handleCustomerChange('trade_name', event.target.value)}
        inputRef={inputs['customer.trade_name']}
      />

      <TextField
        label="Telefone"
        placeholder="Informe telefone"
        margin="normal"
        autoFocus
        fullWidth
        helperText={validation['customer.phone']}
        error={!!validation['customer.phone']}
        value={customer?.customer.phone}
        onChange={event => handleCustomerChange('phone', event.target.value)}
        inputRef={inputs['customer.phone']}
      />

      <TextField
        label="CEP"
        placeholder="Informe o CEP"
        margin="normal"
        autoFocus
        fullWidth
        helperText={validation['customer.postal_code']}
        error={!!validation['customer.postal_code']}
        value={customer?.customer.postal_code}
        onChange={event => handleCustomerChange('postal_code', event.target.value)}
        inputRef={inputs['customer.postal_code']}
        InputProps={{
          inputComponent: PostalCodeInput as any,
        }}
      />

      <div className="two-columns">
        <TextField
          label="Endereço"
          placeholder="Informe o endereço"
          margin="normal"
          autoFocus
          fullWidth
          helperText={validation['customer.street']}
          error={!!validation['customer.street']}
          value={customer?.customer.street}
          onChange={event => handleCustomerChange('street', event.target.value)}
          inputRef={inputs['customer.street']}
        />

        <TextField
          label="Número do endereço"
          placeholder="Informe o número"
          margin="normal"
          autoFocus
          fullWidth
          helperText={validation['customer.street_number']}
          error={!!validation['customer.street_number']}
          value={customer?.customer.street_number}
          onChange={event => handleCustomerChange('street_number', event.target.value)}
          inputRef={inputs['customer.street_number']}
        />
      </div>

      <TextField
        label="Complemento"
        placeholder="Informe o complemento"
        margin="normal"
        autoFocus
        fullWidth
        helperText={validation['customer.complement']}
        error={!!validation['customer.complement']}
        value={customer?.customer.complement}
        inputRef={inputs['customer.complement']}
        onChange={event => handleCustomerChange('complement', event.target.value)}
      />

      <TextField
        label="Bairro"
        placeholder="Informe o bairro"
        margin="normal"
        autoFocus
        fullWidth
        helperText={validation['customer.neighborhood']}
        error={!!validation['customer.neighborhood']}
        value={customer?.customer.neighborhood}
        onChange={event => handleCustomerChange('neighborhood', event.target.value)}
        inputRef={inputs['customer.neighborhood']}
      />

      <div className="two-columns">
        <TextField
          label="Cidade"
          placeholder="Informe a cidade"
          margin="normal"
          autoFocus
          fullWidth
          helperText={validation['customer.city']}
          error={!!validation['customer.city']}
          value={customer?.customer.city}
          onChange={event => handleCustomerChange('city', event.target.value)}
          inputRef={inputs['customer.city']}
        />

        <TextField
          label="Estado"
          placeholder="Informe o estado"
          margin="normal"
          autoFocus
          fullWidth
          helperText={validation['customer.state']}
          error={!!validation['customer.state']}
          value={customer?.customer.state}
          onChange={event => handleCustomerChange('state', event.target.value)}
          inputRef={inputs['customer.state']}
        />
      </div>
    </Container>
  );
};

export default CustomerForm;
