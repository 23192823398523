import React from 'react';
import { ListItem, Typography } from '@mui/material';
import { history } from 'services/history';
import { moneyFormat } from 'helpers/numberFormat';
import { makeStyles } from '@mui/styles';
import { BlancaLuzLoan } from 'types/blancaLuzLoan';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  content: {
    display: 'grid',
    rowGap: '7px',
    marginTop: 10,
    '& > p': {
      display: 'grid',
      gap: '0px',
      gridTemplateColumns: '120px 1fr',
    },
  },
});

type WaitingLoanItemModuleProps = {
  loan: BlancaLuzLoan;
};

const WaitingLoanItemModule: React.FC<WaitingLoanItemModuleProps> = ({ loan }) => {
  const classes = styles();

  return (
    <ListItem className={classes.container} button onClick={() => history.push(`/waiting-loan/${loan.id}`)}>
      <Typography variant="caption" color="primary">
        Parcela nº {loan.id}
      </Typography>
      <div className={classes.content}>
        <Typography>
          <span>Nome</span>
          <span>{loan.customer_representative_name}</span>
        </Typography>
        <Typography>
          <span>Valor</span>
          <span>{moneyFormat(loan.installment_value)}</span>
        </Typography>
        <Typography>
          <span>QTD Parcelas</span>
          <span>{loan.installment_quantity}</span>
        </Typography>
        <Typography>
          <span>Criado em</span>
          <span>{loan.formattedCreatedAt}</span>
        </Typography>
      </div>
    </ListItem>
  );
};

export default WaitingLoanItemModule;
