import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Done } from '@mui/icons-material';

type SettingsActionsProps = {
  handleSubmit(): void;
};

const SettingsActions: React.FC<SettingsActionsProps> = ({ handleSubmit }) => {
  return (
    <>
      <Tooltip title="Salvar">
        <IconButton color="inherit" onClick={handleSubmit}>
          <Done />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default SettingsActions;
