import { createContext, useContext } from 'react';
import { Customer, CustomerInfo } from 'types/customer';

interface CustomerContextValue {
  customer: Customer | null;
  handleUserChange(index: keyof Customer, value: any): void;
  handleCustomerChange(index: keyof CustomerInfo, value: any): void;
}

const CustomerContext = createContext<CustomerContextValue>({} as CustomerContextValue);
export const CustomerProvider = CustomerContext.Provider;
export const CustomerConsumer = CustomerContext.Consumer;

export function useCustomer(): CustomerContextValue {
  return useContext(CustomerContext);
}
