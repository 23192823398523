import React from 'react';
import UserItem from './UserItem';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { userTableTamplate } from '../userTableTemplate';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { makeStyles } from '@mui/styles';
import { history } from 'services/history';
import { Grid, Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { AdminUser } from 'types/admin-user';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type UserListProps = {
  users: AdminUser[];
  handleSort(id: string): void;
  orderedIndex: OrderIndexData;
};

const UserList: React.FC<UserListProps> = ({ users, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { page, rowsPerPage } = usePagination();

  function handleClick(id?: string) {
    history.push(`/user/${id}`);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={userTableTamplate}>
          <TableContent>
            <TableHeader>
              {userTableTamplate.map(item => (
                <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
                  <Typography variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </div>
              ))}
            </TableHeader>
            <TableBody>
              {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => (
                <TableRow onClick={() => handleClick(user.id)} key={user.id}>
                  <UserItem user={user} />
                </TableRow>
              ))}
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default UserList;
