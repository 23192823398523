import React, { useCallback, useEffect, useState } from 'react';
import { api } from 'services/api';
import { ptBR } from 'date-fns/locale';
import useTableOrder from 'hooks/tableOrder';
import Appbar from 'components/appbar/Appbar';
import { Button, Theme } from '@mui/material';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { format, parseISO, subDays } from 'date-fns';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableContainer from 'components/table/TableContainer';
import WaitingLoanActions from './WaitingLoanActions';
import WaitingLoanFilterBox from './WaitingLoanFilterBox';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { WaitingLoanProvider } from './hooks/useWaitingLoan';
import WaitingLoanListTable from './list/table/WaitingLoanListTable';
import { waitingLoanTableTemplate } from './waitingLoanTableTemplate';
import MobileSearch from 'components/search/MobileSearch';
import ApiPagination from 'components/pagination/ApiPagination';
import WaitingLoanListModule from './list/module/WaitingLoanListModule';
import { moneyFormat } from 'helpers/numberFormat';
import LoanListMenu from './list/anchor/WaitingLoanListMenu';
import { makeStyles } from '@mui/styles';
import { history } from 'services/history';
import { useApp } from 'hooks/useApp';
import { BlancaLuzLoan } from 'types/blancaLuzLoan';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

export type WaitingLoanQueryParams = {
  initial_date: Date;
  final_date: Date;
  customer_representative_name: string;
  status: 'approved' | 'pending' | 'disapproved';
};

const queryParamsInitialValue: WaitingLoanQueryParams = {
  initial_date: subDays(new Date(), 365),
  final_date: new Date(),
  customer_representative_name: '',
  status: 'pending',
};

let timer: NodeJS.Timeout;

const WaitingLoanPage: React.FC = () => {
  const app = useApp();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [orderedIndex, sort] = useTableOrder();
  const [searchDialog, setSearchDialog] = useState(false);
  const [Loans, setLoans] = useState<BlancaLuzLoan[]>([]);
  const [filtered, setFiltered] = useState<BlancaLuzLoan[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [selectedLoan, setSelectedLoan] = useState<null | BlancaLuzLoan>(null);
  const [queryParams, setQueryParams] = useState<WaitingLoanQueryParams>(queryParamsInitialValue);

  const fetchLoansWaiting = useCallback(
    (query?: WaitingLoanQueryParams) => {
      setLoading(true);
      api
        .get('/loans', {
          params: { ...query, page: page + 1, rows },
        })
        .then(_response => {
          const response = _response.data;
          setLoans(
            response.data.map((item: BlancaLuzLoan) => {
              item.formattedCreatedAt = format(parseISO(item.created_at), 'P', { locale: ptBR });
              item.formattedInstallmentValue = moneyFormat(item.installment_value);
              item.formattedValue = moneyFormat(item.value);

              return item;
            })
          );
          setTotal(response.total);
        })
        .catch(err => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    },
    [page, rows]
  );

  useEffect(() => {
    setFiltered(Loans);
  }, [Loans]);

  useEffect(() => {
    fetchLoansWaiting(queryParamsInitialValue);
  }, [fetchLoansWaiting]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleQueryParamsChange(index: keyof WaitingLoanQueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));

    const query = {
      ...queryParams,
      [index]: value,
    };

    clearTimeout(timer);

    if (index !== 'customer_representative_name') {
      fetchLoansWaiting(query);
      return;
    }

    timer = setTimeout(() => fetchLoansWaiting(query), 500);
  }

  return (
    <WaitingLoanProvider value={{ selectedLoan, setSelectedLoan }}>
      {searchDialog && (
        <MobileSearch
          loading={loading}
          handleQueryParamsChange={handleQueryParamsChange}
          onExited={() => setSearchDialog(false)}
          queryParams={queryParams}
        />
      )}

      <Appbar
        title="BLZ Cred Contratos"
        ActionsComponent={<WaitingLoanActions openDialog={() => setSearchDialog(true)} />}
      />
      <PageHeaderActions
        title="BLZ Cred Contratos"
        description="Gestão dos Contratos"
        ActionComponent={
          <Button size="small" variant="contained" color="primary" onClick={() => history.push('/blanca-luz-Loan')}>
            Adicionar
          </Button>
        }
      />
      <TableContainer tableTemplate={waitingLoanTableTemplate}>
        <WaitingLoanFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          handleQueryParamsChange={handleQueryParamsChange}
          queryParams={queryParams}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum empréstimo para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              <LoanListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

              {displayMode === 'list' ? (
                <WaitingLoanListTable
                  setAnchorEl={setAnchorEl}
                  loans={filtered}
                  handleSort={handleSort}
                  orderedIndex={orderedIndex}
                />
              ) : (
                displayMode === 'module' && <WaitingLoanListModule loans={filtered} />
              )}
              <ApiPagination
                onChangePage={value => setPage(value)}
                onChangeRowsPerPage={value => setRows(value)}
                count={total}
              />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </WaitingLoanProvider>
  );
};

export default WaitingLoanPage;
