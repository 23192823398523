import React from 'react';
import { TablePagination as NativeTablePagination } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  selectRoot: {
    marginRight: 10,
  },
  toolbar: {
    paddingLeft: '0!important',
  },
  actions: {
    marginLeft: 0,
  },
});

type ApiPaginationProps = {
  count: number;
  onChangePage(page: number): void;
  onChangeRowsPerPage(rows: number): void;
};

const ApiPagination: React.FC<ApiPaginationProps> = ({ count, onChangePage, onChangeRowsPerPage }) => {
  const { rowsPerPage, page, rowsPerPageOption, handleSetPage, handleSetRowsPerPage } = usePagination();
  const classes = useStyles();

  function handlePageChange(page: number) {
    onChangePage(page);
    handleSetPage(page);
  }

  function handleRowsPerPageChange(rows: number) {
    handleSetRowsPerPage(rows);
    onChangeRowsPerPage(rows);
  }

  return (
    <NativeTablePagination
      classes={{ selectRoot: classes.selectRoot, toolbar: classes.toolbar, actions: classes.actions }}
      labelRowsPerPage="Registros"
      rowsPerPageOptions={rowsPerPageOption}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'Página anterior',
      }}
      nextIconButtonProps={{
        'aria-label': 'Próxima Página',
      }}
      onPageChange={(e, page) => handlePageChange(page)}
      onRowsPerPageChange={e => handleRowsPerPageChange(parseFloat(e.target.value))}
    />
  );
};

export default ApiPagination;
