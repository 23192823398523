import './index.css';
import App from './App';
import { store } from 'store';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>
);
