import React, { useEffect, useState } from 'react';
import { theme } from 'config/theme';
import AppRoutes from 'routes/Routes';
import ApiProvider from 'providers/api';
import { ThemeProvider } from '@mui/material';
import { mobileCheck } from 'helpers/MobileCheck';
import MessagingProvider from 'providers/messaging';
import AuthProvider from 'providers/auth';
import { AppContextData, AppProvider } from 'hooks/useApp';
import { BrowserRouter } from 'routes/BrowserRouter';
import { history } from 'services/history';
import SplashScreen from 'components/splash-screen/SplashScreen';

const App: React.FC = () => {
  const [isMobile, setIsMobile] = useState(mobileCheck());
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpenedMenu, setIsOpenedMenu] = useState(!mobileCheck() && windowWidth > 1280);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  function handleResize() {
    setIsMobile(mobileCheck());
    setWindowWidth(window.innerWidth);
  }

  function handleOpenMenu() {
    setIsOpenedMenu(!isOpenedMenu);
  }

  const appContextValue: AppContextData = {
    isMobile,
    windowWidth,
    isOpenedMenu,
    handleOpenMenu,
  };

  return (
    <BrowserRouter history={history}>
      <ApiProvider>
        <AppProvider value={appContextValue}>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <SplashScreen />
              <MessagingProvider>
                <AppRoutes />
              </MessagingProvider>
            </ThemeProvider>
          </AuthProvider>
        </AppProvider>
      </ApiProvider>
    </BrowserRouter>
  );
};

export default App;
