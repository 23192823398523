import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useBlancaLuzInstallments } from '../hooks/useBlancaLuzInstallments';

type InstallmentsListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const InstallmentsListMenu: React.FC<InstallmentsListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { handleDelete } = useBlancaLuzInstallments();

  function handleDeleteClick() {
    setAnchorEl(null);
    handleDelete();
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
    </Menu>
  );
};

export default InstallmentsListMenu;
