import React from 'react';
import SplashScreenContent from './SplashScreenContent';
import { useAuth } from 'providers/auth';

const SplashScreen: React.FC = () => {
  const { isLoading } = useAuth();

  return <>{isLoading && <SplashScreenContent />}</>;
};

export default SplashScreen;
