import { Dispatch, SetStateAction, useState } from 'react';
import { BlancaLuzLoan } from 'types/blancaLuzLoan';
import * as yup from 'yup';

export type BlancaLuzLoanValidation = {
  customer_representative_name?: string;
  value?: string;
  pix_key?: string;
  installment_quantity?: string;
};

type UseBlancaLuzLoanValidation = [
  BlancaLuzLoanValidation,
  Dispatch<SetStateAction<BlancaLuzLoanValidation>>,
  (loan: BlancaLuzLoan) => Promise<void>
];

export function useBlancaLuzLoanValidation(): UseBlancaLuzLoanValidation {
  async function handleValidation(loan: BlancaLuzLoan) {
    const schema = yup.object().shape({
      pix_key: yup.string().required('Chave pix é obrigatório'),
      installment_quantity: yup.string().required('Quantidade de parcelas é obrigatório'),
      value: yup.string().required('Valor é obrigatório'),
      customer_representative_name: yup.string().required('Nome é obrigatório'),
    });

    try {
      await schema.validate(loan);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path) {
        setValidation({
          [error.path]: error.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<BlancaLuzLoanValidation>({});
  return [validation, setValidation, handleValidation];
}
