import React, { useEffect, useRef } from 'react';
import { FormControlLabel, Switch, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { City } from 'types/city';
import { CityValidation } from './validation/cityValidation';

const useStyles = makeStyles((theme: Theme) => ({
  switch: {
    marginTop: 20,
  },
  container: {
    display: 'flex',
    marginBottom: 200,
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {},
    maxWidth: '400px',
  },
  imageContainer: {
    marginTop: 20,
  },
}));

type CityFormProps = {
  city: City;
  handleChange(index: keyof City, value: any): void;
  validation: CityValidation;
};

const CityForm: React.FC<CityFormProps> = ({ city, handleChange, validation }) => {
  const classes = useStyles();

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    ibge_code: useRef<HTMLInputElement>(null),
    state: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      <div className={classes.container}>
        <TextField
          inputRef={inputs.name}
          error={!!validation.name}
          helperText={validation.name}
          label="Nome"
          placeholder="Informe o município"
          margin="normal"
          fullWidth
          value={city.name || ''}
          onChange={e => handleChange('name', e.target.value)}
        />

        <TextField
          inputRef={inputs.state}
          error={!!validation.state}
          helperText={validation.state}
          label="Estado"
          placeholder="Informe o estado"
          margin="normal"
          fullWidth
          value={city.state || ''}
          onChange={e => handleChange('state', e.target.value)}
        />

        <TextField
          inputRef={inputs.ibge_code}
          error={!!validation.ibge_code}
          helperText={validation.ibge_code}
          label="Código IBGE"
          placeholder="Informe o código IBGE"
          margin="normal"
          fullWidth
          value={city.ibge_code || ''}
          onChange={e => handleChange('ibge_code', e.target.value)}
          type="number"
        />

        <FormControlLabel
          control={
            <Switch checked={city.active} onChange={e => handleChange('active', e.target.checked)} color="primary" />
          }
          label="Ativo"
        />

        <button type="submit" style={{ display: 'none' }} />
      </div>
    </>
  );
};

export default CityForm;
