import React from 'react';
import List from 'components/list/List';
import BlancaLuzLoanItemModule from './BlancaLuzLoanItemModule';
import { BlancaLuzLoan } from 'types/blancaLuzLoan';

type BlancaLuzLoanListModuleProps = {
  loans: BlancaLuzLoan[];
};

const BlancaLuzLoanListModule: React.FC<BlancaLuzLoanListModuleProps> = ({ loans }) => {
  return (
    <List>
      {loans.map(loan => (
        <BlancaLuzLoanItemModule key={loan.id} loan={loan} />
      ))}
    </List>
  );
};

export default BlancaLuzLoanListModule;
