import React, { useCallback, useContext, useState, useEffect, ReactNode } from 'react';
import { api } from 'services/api';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from 'store/modules/user/actions';
import { parseJwt } from 'helpers/jwtDecode';

export type Tokens = {
  access_token: string;
  refresh_token: string;
};

export function setTokens(tokens: Tokens) {
  localStorage.setItem('blz-cred@access-token', tokens.access_token);
  localStorage.setItem('blz-cred@refresh-token', tokens.refresh_token);
}

export function removeTokens() {
  localStorage.removeItem('blz-cred@access-token');
  localStorage.removeItem('blz-cred@refresh-token');
}

export function getAccessToken() {
  return localStorage.getItem('blz-cred@access-token');
}

export function getRefreshToken() {
  return localStorage.getItem('blz-cred@refresh-token');
}

interface AuthContextData {
  login(email: string, password: string): Promise<void>;
  logout(): Promise<void>;
  checkAuth(): boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  loggingOff: boolean;
}

const AuthContext = React.createContext({} as AuthContextData);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [loggingOff, setLoggingOff] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const loadUser = useCallback(async () => {
    const access_token = getAccessToken();

    if (!access_token) {
      return;
    }

    const payload = parseJwt(access_token) as any;

    try {
      setIsLoading(true);
      const response = await api.get(`/admin-users/${payload.id}`);
      dispatch(setUser(response.data));
      setIsAuthenticated(true);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const login = useCallback(
    async (email: string, password: string) => {
      const response = await api.post('/auth/login', { email, password });

      setTokens({
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
      });

      dispatch(setUser(response.data.user));

      setIsAuthenticated(true);
    },
    [dispatch]
  );

  const logout = useCallback(async (): Promise<void> => {
    setLoggingOff(true);

    try {
      await api.post('/auth/logout');
      removeTokens();
      setIsAuthenticated(false);
      dispatch(setUser(null));
      history('/login');
      setLoggingOff(false);
    } catch (err) {
      setLoggingOff(false);
    }
  }, [dispatch, history]);

  const checkAuth = useCallback((): boolean => {
    const token = getAccessToken();

    return !!token;
  }, []);

  return (
    <AuthContext.Provider value={{ login, logout, checkAuth, isAuthenticated, isLoading, loggingOff }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  return context;
}

export default AuthProvider;
