import React from 'react';
import { Button, Theme } from '@mui/material';
import { history } from 'services/history';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const CityActions: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button size="small" color="inherit" onClick={() => history.push('/city/new')}>
        Adicionar
      </Button>
    </div>
  );
};

export default CityActions;
