import { SiteSetting } from 'types/siteSetting';

export const SETTINGS_CHANGE = 'SETTINGS_CHANGE';
export const SET_SETTINGS = 'SET_SETTINGS';

export type SettingsIndexes = keyof SiteSetting;

interface SettingsChangeAction {
  type: typeof SETTINGS_CHANGE;
  index: SettingsIndexes;
  value: any;
}

interface SetSettingsAction {
  type: typeof SET_SETTINGS;
  settings: SiteSetting;
}

export type SettingsActionsType = SettingsChangeAction | SetSettingsAction;
