import React, { useContext, ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosInstance, CancelTokenSource } from 'axios';

interface ApiContextData {
  getCancelTokenSource(): CancelTokenSource;
  api: () => AxiosInstance;
}

const ApiContext = React.createContext({} as ApiContextData);

interface ApiProviderProps {
  children: ReactNode;
}

const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const history = useNavigate();

  function getCancelTokenSource(): CancelTokenSource {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return source;
  }

  const api = useCallback(() => {
    const apiBlzCred = axios.create({
      baseURL: process.env.REACT_APP_API,
    });

    apiBlzCred.interceptors.request.use(
      config => {
        const token = localStorage.getItem('blz-cred@access-token');
        if (config.headers) if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
      },

      err => {
        return Promise.reject(err);
      }
    );

    apiBlzCred.interceptors.response.use(
      config => {
        return config;
      },
      err => {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem('blz-cred@access-token');
          history('/login');
        }

        return Promise.reject(err);
      }
    );

    return apiBlzCred;
  }, [history]);

  return <ApiContext.Provider value={{ api, getCancelTokenSource }}>{children}</ApiContext.Provider>;
};

export function useApi(): ApiContextData {
  const context = useContext(ApiContext);

  if (!context) throw new Error('This hook must be in Api Context Component');

  return context;
}

export default ApiProvider;
