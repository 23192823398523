import { Menu, MenuItem } from '@mui/material';
import Loading from 'components/loading/Loading';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { api } from 'services/api';
import { history } from 'services/history';
import { useFailedLoan } from '../../hooks/useFailedLoan';

type FailedLoanListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const FailedLoanListMenu: React.FC<FailedLoanListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const [loading, setLoading] = useState(false);
  const { selectedLoan } = useFailedLoan();

  function handleClickApprove() {
    setLoading(true);

    api
      .post(`/loans/${selectedLoan?.id}/approve`)
      .then(() => history.push('/blanca-luz-loan'))
      .catch(err => console.error(err.response.data.error))
      .finally(() => {
        setLoading(false);
        setAnchorEl(null);
      });
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      {loading && <Loading />}
      <MenuItem onClick={handleClickApprove}>Aprovar contrato</MenuItem>
    </Menu>
  );
};

export default FailedLoanListMenu;
