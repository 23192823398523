import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Done } from '@mui/icons-material';

type BlancaLuzLoanActionsProps = {
  handleSubmit(): void;
};

const BlancaLuzLoanActions: React.FC<BlancaLuzLoanActionsProps> = ({ handleSubmit }) => {
  return (
    <Tooltip title="Salvar">
      <IconButton color="inherit" onClick={handleSubmit}>
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default BlancaLuzLoanActions;
