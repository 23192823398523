import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { BlancaLuzLoan } from 'types/blancaLuzLoan';

type FailedLoanContextValue = {
  selectedLoan: BlancaLuzLoan | null;
  setSelectedLoan: Dispatch<SetStateAction<BlancaLuzLoan | null>>;
};

const FailedLoanContext = createContext<FailedLoanContextValue>({} as FailedLoanContextValue);
export const FailedLoanProvider = FailedLoanContext.Provider;

export function useFailedLoan(): FailedLoanContextValue {
  const context = useContext(FailedLoanContext);
  return context;
}
