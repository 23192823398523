import Appbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import WaitingLoanForm from '../WaitingLoanForm';
import { history } from 'services/history';
import { api } from 'services/api';
import BlancaLuzLoanActions from './WaitingLoanActions';
import { useWaitingLoanValidation } from '../validation/waitingLoanValidation';
import { useMessaging } from 'providers/messaging';
import { BlancaLuzLoan } from 'types/blancaLuzLoan';

const WaitingLoanUpdate: React.FC = () => {
  const [loan, setLoan] = useState<BlancaLuzLoan>({} as BlancaLuzLoan);
  const [validation, setValidation, validate] = useWaitingLoanValidation();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { handleOpen } = useMessaging();

  useEffect(() => {
    api
      .get(`/loans/${id}`)
      .then(response => {
        setLoan(response.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function handleChange(index: keyof BlancaLuzLoan, value: any) {
    setLoan(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(loan)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/loans/${id}`, loan)
      .then(() => {
        setSaving(false);
        history.push('/waiting-loan');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="BLZ Cred Contrato"
        ActionsComponent={<BlancaLuzLoanActions saving={saving} loading={loading} handleSubmit={handleValidation} />}
      />
      <PageHeader title="Editar contrato" backUrl="/waiting-loan" />
      {loading ? (
        <InsideLoading />
      ) : (
        <form onSubmit={handleValidation}>
          <WaitingLoanForm handleChange={handleChange} loan={loan} validation={validation} />
        </form>
      )}
    </>
  );
};

export default WaitingLoanUpdate;
