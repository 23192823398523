import { Dispatch, SetStateAction, useState } from 'react';
import { SiteSetting } from 'types/siteSetting';
import * as yup from 'yup';

export type SiteSettingValidation = {
  contact_email?: string;
  primary_color?: string;
  secondary_color?: string;
  facebook_profile_url?: string;
  instagram_profile_url?: string;
  whatsapp?: string;
  youtube_channel_url?: string;
  home_title?: string;
  home_description?: string;
  home_keywords?: string;
  home_image_url?: string;
  footer_image_url?: string;
  about_page_content?: string;
};

type UseSiteSettingValidation = [
  SiteSettingValidation,
  Dispatch<SetStateAction<SiteSettingValidation>>,
  (setting: SiteSetting) => Promise<void>
];

export function useSiteSettingValidation(): UseSiteSettingValidation {
  async function handleValidation(setting: SiteSetting) {
    const schema = yup.object().shape({
      footer_image_url: yup.string().required('A imagem da rodapé é obrigatória'),
      home_image_url: yup.string().required('A imagem da página inicial é obrigatória'),
      home_keywords: yup.string().required('As keywords da página inicial obrigatório'),
      home_description: yup.string().required('A descrição da página inicial é obrigatório'),
      home_title: yup.string().required('O título da página inicial é obrigatório'),
      youtube_channel_url: yup.string().required('O endereço do canal do youtube é obrigatório'),
      instagram_profile_url: yup.string().required('O endereço do perfil no twitter é obrigatório'),
      facebook_profile_url: yup.string().required('O endereço do perfil no facebook é obrigatório'),
      secondary_color: yup.string().required('A cor secundária obrigatório'),
      primary_color: yup.string().required('A cor primária é obrigatória'),
      about_page_content: yup
        .string()
        .required('A apresentação da empresa é obrigatória')
        .max(255, 'Número máximo de caracteres atingido (MÁX: 255).'),
      contact_email: yup.string().required('O e-mail de contato é obrigatório'),
    });

    try {
      await schema.validate(setting);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [validation, setValidation] = useState<SiteSettingValidation>({});
  return [validation, setValidation, handleValidation];
}
