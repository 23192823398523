import React, { useState, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import { City as Country } from 'types/city';
import { api } from 'services/api';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { Button } from '@mui/material';
import { history } from 'services/history';
import CityActions from './CityActions';
import TableContainer from 'components/table/TableContainer';
import { cityTableTemplate } from './cityTableTemplate';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'hooks/useApp';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import PaginationProvider from 'hooks/pagination';
import NoData from 'components/no-data/NoData';
import CityListTable from './list/table/CityListTable';
import ApiPagination from 'components/pagination/ApiPagination';
import CityListModule from './list/module/CityListModule';

const City: React.FC = () => {
  const app = useApp();
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const [total, setTotal] = useState(0);
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState<Country[]>([]);
  const [filtered, setFiltered] = useState<Country[]>([]);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  useEffect(() => {
    setFiltered(cities);
  }, [cities]);

  useEffect(() => {
    setLoading(true);

    api
      .get('/cities', {
        params: { page: page + 1, rows },
      })
      .then(_response => {
        const response: Country[] = _response.data;
        setCities(
          response
            .map((item: Country) => {
              item.formattedActive = item.active ? 'Sim' : 'Não';
              return item;
            })
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        );
        setTotal(response.length);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [page, rows]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  return (
    <div>
      <Appbar title="Cadastro de municípios" ActionsComponent={<CityActions />} />
      <PageHeaderActions
        ActionComponent={
          <Button size="small" variant="contained" color="primary" onClick={() => history.push('/city/new')}>
            Adicionar
          </Button>
        }
        title="Cadastro de municípios"
        description="Gerencie os municípios cadastrados"
      />

      <TableContainer tableTemplate={cityTableTemplate}>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhuma cidade para mostrar" />
        ) : (
          <PaginationProvider>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {displayMode === 'list' ? (
                <CityListTable cities={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <CityListModule cities={filtered} />
              )}
              <ApiPagination
                onChangePage={value => setPage(value)}
                onChangeRowsPerPage={value => setRows(value)}
                count={total}
              />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </div>
  );
};

export default City;
