import React from 'react';
import { IconButton } from '@mui/material';
import { Done } from '@mui/icons-material';

type AccountActionsProps = {
  handleSubmit(): void;
};

const AccountActions: React.FC<AccountActionsProps> = ({ handleSubmit }) => {
  return (
    <>
      <IconButton color="inherit" onClick={handleSubmit}>
        <Done />
      </IconButton>
    </>
  );
};

export default AccountActions;
