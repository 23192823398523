import React from 'react';
import { Typography, Theme, ListItemButton } from '@mui/material';
import { history } from 'services/history';
import { makeStyles } from '@mui/styles';
import { CustomerInfo } from 'types/customer';

type CustomerItemModuleProps = {
  customer: CustomerInfo;
};

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .data': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  imageWrapper: {
    display: 'flex',
    height: 70,
    width: 70,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  img: {
    width: '100%',
  },
}));

const CustomerItemModule: React.FC<CustomerItemModuleProps> = ({ customer }) => {
  const classes = useStyles();

  function handleClick(id?: string) {
    history.push(`/customer/${id}`);
  }

  return (
    <ListItemButton className={classes.listItem} onClick={() => handleClick(customer.user_id)}>
      <div className="data">
        <Typography variant="h6">{customer.phone}</Typography>
        <Typography variant="caption" color="textSecondary">
          {customer.trade_name}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {customer.document_number}
        </Typography>
      </div>
    </ListItemButton>
  );
};

export default CustomerItemModule;
