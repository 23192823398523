import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { InputAdornment, TextField, Theme } from '@mui/material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { BlancaLuzLoanQueryParams } from './BlancaLuzLoan';
import { Search } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    display: 'grid',
    gridTemplateColumns: '220px 1fr',
    columnGap: 10,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '120px 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      flex: 1,
    },
    '& > .search-params': {
      display: 'grid',
      gridTemplateColumns: '150px 150px 150px',
      columnGap: 20,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}));

type BlancaLuzLoanFilterBoxProps = {
  queryParams: BlancaLuzLoanQueryParams;
  handleQueryParamsChange(index: keyof BlancaLuzLoanQueryParams, value: any): void;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const BlancaLuzLoanFilterBox: React.FC<BlancaLuzLoanFilterBoxProps> = ({
  queryParams,
  handleQueryParamsChange,
  setDisplayMode,
  displayMode,
}) => {
  const classes = useStyles();
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, []);

  return (
    <FilterBox>
      <div className={classes.filter}>
        <TextField
          label="Nome do cliente"
          placeholder="Pesquisar"
          value={queryParams.customer_representative_name}
          onChange={e => handleQueryParamsChange('customer_representative_name', e.target.value)}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={queryParams.initial_date}
            onChange={date => handleQueryParamsChange('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data Final"
            value={queryParams.final_date}
            onChange={date => handleQueryParamsChange('final_date', date)}
          />
        </div>
      </div>

      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default BlancaLuzLoanFilterBox;
