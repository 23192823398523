import { Dispatch, SetStateAction, useState } from 'react';
import { BlancaLuzInstallment } from 'types/blancaLuzInstallment';
import * as yup from 'yup';

export type BlancaLuzInstallmentValidation = {
  installment?: string;
  fee?: string;
  daily_iof?: string;
  additional_iof?: string;
  factor?: string;
  due_days?: string;
};

type UseBlancaLuzInstallmentValidation = [
  BlancaLuzInstallmentValidation,
  Dispatch<SetStateAction<BlancaLuzInstallmentValidation>>,
  (installment: BlancaLuzInstallment) => Promise<void>
];

export function useBlancaLuzInstallmentValidation(): UseBlancaLuzInstallmentValidation {
  async function handleValidation(installment: BlancaLuzInstallment) {
    const schema = yup.object().shape({
      installment: yup
        .number()
        .typeError('Deve ser informado um número válido')
        .required('O número da parcela é obrigatório'),
      fee: yup.number().typeError('Deve ser informado um número válido').required('O juros é obrigatório'),
      additional_iof: yup
        .number()
        .typeError('Deve ser informado um número válido')
        .required('O IOF adicional é obrigatório'),
      daily_iof: yup.number().typeError('Deve ser informado um número válido').required('O IOF diário é obrigatório'),
      factor: yup.number().typeError('Deve ser informado um número válido').required('O fator é obrigatório'),
      due_days: yup.number().typeError('Deve ser informado um número válido').required('O vencimento é obrigatório'),
    });

    try {
      await schema.validate(installment);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path) {
        setValidation({
          [error.path]: error.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<BlancaLuzInstallmentValidation>({});
  return [validation, setValidation, handleValidation];
}
