import { UserActionsType, USER_CHANGE, User, SET_USER } from './types';

export function userChange(index: string, value: string): UserActionsType {
  return {
    type: USER_CHANGE,
    index,
    value,
  };
}

export function setUser(user: User): UserActionsType {
  return {
    type: SET_USER,
    user,
  };
}
