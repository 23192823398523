import React from 'react';
import SidebarItem from '../SidebarItem';
import { SIDEBAR_WIDTH } from 'constants/constants';
import { useSelector } from 'store/selector';
import SidebarBackAction from '../SidebarBackAction';
import { useApp } from 'hooks/useApp';
import { IconButton, Theme, Typography } from '@mui/material';
import { ArrowBack, People } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#272c3a',
    zIndex: 2,
    transform: `translateX(-${SIDEBAR_WIDTH}px)`,
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  drawerHeader: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 5,
  },
}));

type UserControlSidebarProps = {
  show: boolean;
  handleClose(): void;
};

const UserControlSidebar: React.FC<UserControlSidebarProps> = ({ show, handleClose }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const app = useApp();

  return (
    <div className={classes.container} style={{ transform: show ? 'translateX(0)' : undefined }}>
      <div className={classes.drawerHeader}>
        <IconButton color="inherit" onClick={handleClose}>
          <ArrowBack />
        </IconButton>
        <Typography color="inherit" variant="h6">
          Controle de usuário
        </Typography>
      </div>
      {user && (
        <>
          {!app.isMobile && app.windowWidth > 960 && (
            <SidebarBackAction handleClick={handleClose} icon={<ArrowBack />} text="voltar" />
          )}
          <SidebarItem to="/users" icon={<People />} text="Usuários" />
        </>
      )}
    </div>
  );
};

export default UserControlSidebar;
