import React from 'react';
import List from 'components/list/List';
import FailedLoanItemModule from './FailedLoanItemModule';
import { BlancaLuzLoan } from 'types/blancaLuzLoan';

type WaitingILoanListModuleProps = {
  loans: BlancaLuzLoan[];
};

const WaitingILoanListModule: React.FC<WaitingILoanListModuleProps> = ({ loans }) => {
  return (
    <List>
      {loans.map(loan => (
        <FailedLoanItemModule key={loan.id} loan={loan} />
      ))}
    </List>
  );
};

export default WaitingILoanListModule;
