import { Tab, Tabs } from '@mui/material';
import React from 'react';

export type TabOptions = 'user' | 'company';

interface CustomerTabsProps {
  tab: TabOptions;
  handleChange(tab: TabOptions): void;
}

const CustomerTabs: React.FC<CustomerTabsProps> = ({ tab, handleChange }) => {
  return (
    <Tabs variant="scrollable" value={tab} onChange={(event, value) => handleChange(value)}>
      <Tab label="Representante legal" value="user" />
      <Tab label="Empresa" value="company" />
    </Tabs>
  );
};

export default CustomerTabs;
