import React, { useEffect, useRef } from 'react';
import { FormControlLabel, Switch, TextField, Theme } from '@mui/material';
import { BlancaLuzInstallmentValidation } from './validation/blancaLuzInstallmentValidation';
import { BlancaLuzInstallment } from 'types/blancaLuzInstallment';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  switch: {
    marginTop: 20,
  },
  container: {
    display: 'flex',
    marginBottom: 200,
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {},
    maxWidth: '400px',
  },
  imageContainer: {
    marginTop: 20,
  },
}));

type BlancaLuzInstallmentFormProps = {
  installment: BlancaLuzInstallment;
  handleChange(index: keyof BlancaLuzInstallment, value: any): void;
  validation: BlancaLuzInstallmentValidation;
};

const BlancaLuzInstallmentForm: React.FC<BlancaLuzInstallmentFormProps> = ({
  installment,
  handleChange,
  validation,
}) => {
  const classes = useStyles();

  const inputs = {
    installment: useRef<HTMLInputElement>(null),
    fee: useRef<HTMLInputElement>(null),
    daily_iof: useRef<HTMLInputElement>(null),
    factor: useRef<HTMLInputElement>(null),
    due_days: useRef<HTMLInputElement>(null),
    additional_iof: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      <div className={classes.container}>
        <TextField
          inputRef={inputs.installment}
          error={!!validation.installment}
          helperText={validation.installment}
          label="Número da parcela"
          placeholder="Digite o número da parcela"
          margin="normal"
          fullWidth
          value={installment.installment || ''}
          onChange={e => handleChange('installment', e.target.value)}
          autoFocus
          type="number"
          inputProps={{
            inputMode: 'decimal',
          }}
        />

        <TextField
          inputRef={inputs.fee}
          error={!!validation.fee}
          helperText={validation.fee}
          label="Juros"
          placeholder="Informe o juros"
          margin="normal"
          fullWidth
          value={installment.fee || ''}
          onChange={e => handleChange('fee', e.target.value)}
          inputProps={{
            inputMode: 'decimal',
          }}
          type="number"
        />

        <TextField
          inputRef={inputs.daily_iof}
          error={!!validation.daily_iof}
          helperText={validation.daily_iof}
          label="IOF Diário"
          placeholder="Informe o IOF diário"
          margin="normal"
          fullWidth
          value={installment.daily_iof || ''}
          onChange={e => handleChange('daily_iof', e.target.value)}
          inputProps={{
            inputMode: 'decimal',
          }}
          type="number"
        />

        <TextField
          inputRef={inputs.additional_iof}
          error={!!validation.additional_iof}
          helperText={validation.additional_iof}
          label="IOF Adicional"
          placeholder="Informe o IOF adicional"
          margin="normal"
          fullWidth
          value={installment.additional_iof || ''}
          onChange={e => handleChange('additional_iof', e.target.value)}
          inputProps={{
            inputMode: 'decimal',
          }}
          type="number"
        />

        <TextField
          inputRef={inputs.factor}
          error={!!validation.factor}
          helperText={validation.factor}
          label="Fator"
          placeholder="Informe o fator"
          margin="normal"
          fullWidth
          value={installment.factor || ''}
          onChange={e => handleChange('factor', e.target.value)}
          inputProps={{
            inputMode: 'decimal',
          }}
          type="number"
        />

        <TextField
          inputRef={inputs.due_days}
          error={!!validation.due_days}
          helperText={validation.due_days}
          label="Vencimento"
          placeholder="Informe o vencimento"
          margin="normal"
          fullWidth
          value={installment.due_days || ''}
          onChange={e => handleChange('due_days', e.target.value)}
          inputProps={{
            inputMode: 'decimal',
          }}
          type="number"
        />

        <FormControlLabel
          label="Parcela para o Simples Nacional"
          className={classes.switch}
          control={
            <Switch
              color="primary"
              onChange={event => handleChange('for_simples_nacional', event.target.checked)}
              checked={installment.for_simples_nacional}
            />
          }
        />

        <FormControlLabel
          label="Ativo"
          className={classes.switch}
          control={
            <Switch
              color="primary"
              onChange={event => handleChange('is_active', event.target.checked)}
              checked={installment.is_active}
            />
          }
        />

        <button type="submit" style={{ display: 'none' }} />
      </div>
    </>
  );
};

export default BlancaLuzInstallmentForm;
