import axios, { CancelTokenSource, AxiosError } from 'axios';
import { getAccessToken, getRefreshToken, removeTokens, setTokens } from 'providers/auth';
import { history } from './history';

type FailedRequestQueue = {
  onSuccess: () => void;
  onFailure: (err: AxiosError) => void;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

let isRefreshing = false;
let failedRequestQueue: FailedRequestQueue[] = [];

api.interceptors.request.use(
  config => {
    const token = getAccessToken();

    if (config.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (err: AxiosError) => Promise.reject(err)
);

api.interceptors.response.use(
  config => {
    return config;
  },
  (err: AxiosError<any>) => {
    const refresh_token = getRefreshToken();
    const config = err.config;

    if (err.response?.status !== 401) {
      return Promise.reject(err);
    }

    if (err.response?.data?.code === 'login.invalid_credentials') {
      return Promise.reject(err);
    }

    if (err.response?.data?.code !== 'access_token.expired') {
      history.push('/login');
      removeTokens();
      return Promise.reject(err);
    }

    if (!isRefreshing) {
      isRefreshing = true;

      api
        .post('/auth/refresh', { refresh_token })
        .then(response => {
          setTokens({
            access_token: response.data.access_token,
            refresh_token: response.data.refresh_token,
          });

          failedRequestQueue.forEach(request => request.onSuccess());
        })
        .catch(err => {
          failedRequestQueue.forEach(request => request.onFailure(err));
          removeTokens();
          history.push('/login');
        })
        .finally(() => {
          isRefreshing = false;
          failedRequestQueue = [];
        });
    }

    return new Promise((resolve, reject) => {
      failedRequestQueue.push({
        onSuccess: () => resolve(api(config as any)),
        onFailure: err => reject(err),
      });
    });
  }
);

export function getCancelTokenSource(): CancelTokenSource {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  return source;
}

export { api };
