import React, { FormEvent, useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { api } from 'services/api';
import BlancaLuzInstallmentActions from './BlancaLuzInstallmentActions';
import BlancaLuzInstallmentForm from '../BlancaLuzInstallmentForm';
import { useBlancaLuzInstallmentValidation } from '../validation/blancaLuzInstallmentValidation';
import { BlancaLuzInstallment } from 'types/blancaLuzInstallment';
import { useMessaging } from 'providers/messaging';
import { history } from 'services/history';

const intialValue: BlancaLuzInstallment = {
  id: 0,
  installment: 0,
  due_days: 0,
  factor: 0,
  fee: 0,
  daily_iof: 0,
  additional_iof: 0,
  is_active: false,
  created_at: '',
  formattedForSimplesNacional: 'Não',
  for_simples_nacional: false,
};

const BlancaLuzInstallmentNew: React.FC = () => {
  const [installment, setInstallment] = useState<BlancaLuzInstallment>(intialValue);
  const [validation, setValidation, validate] = useBlancaLuzInstallmentValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();

  const handleChange = useCallback((index: keyof BlancaLuzInstallment, value: any) => {
    setInstallment(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(installment)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/installments', installment)
      .then(() => {
        setSaving(false);
        history.push('/blanca-luz-installments');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="BLZ Cred Parcelas"
        ActionsComponent={<BlancaLuzInstallmentActions handleSubmit={handleValidation} />}
      />
      <PageHeader title="Nova parcela" backUrl="/blanca-luz-installments" />
      <Grid container>
        <form onSubmit={handleValidation}>
          <BlancaLuzInstallmentForm handleChange={handleChange} installment={installment} validation={validation} />
        </form>
      </Grid>
    </>
  );
};

export default BlancaLuzInstallmentNew;
