import React from 'react';
import { makeStyles } from '@mui/styles';
import { ListItem, ListItemIcon, ListItemText, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  listItemIcon: {
    '& > svg': {
      color: '#fff',
    },
  },
  listItemText: {
    color: '#fff',
    fontSize: 14,
  },
  listItem: {
    paddingLeft: 16,
    borderLeft: '2px solid transparent',
    minHeight: 50,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(0 0 0 / 4%)',
    },
  },
}));

interface SidebarItemProps {
  text: string;
  icon: React.ReactElement;
  handleClick(): void;
}

const SidebarBackAction: React.FC<SidebarItemProps> = ({ icon, text, handleClick }) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.listItem} onClick={handleClick}>
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText classes={{ primary: classes.listItemText }} primary={text} />
    </ListItem>
  );
};

export default SidebarBackAction;
