import React, { useEffect, useRef, useState } from 'react';
import { InputAdornment, TextField, styled } from '@mui/material';
import { User } from '../reducer/types';
import { UserValidation } from './NewUser';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Container = styled('div')({
  maxWidth: 600,
  '& .passwords': {
    display: 'flex',
    gap: 15,
  },
  marginTop: 30,
});

type UserFormProps = {
  user: User;
  handleChange(index: string, value: string): void;
  validation: UserValidation;
};

const UserForm: React.FC<UserFormProps> = ({ user, handleChange, validation }) => {
  const [passVisibility, setPassVisibility] = useState(true);
  const [passConfirmVisibility, setPassConfirmVisibility] = useState(true);
  const inputs = {
    name: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    password: useRef<HTMLInputElement>(null),
    password_confirmation: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    if (!inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  function handlePasswordVisibility() {
    setPassVisibility(!passVisibility);
  }

  function handlePasswordConfirmVisibility() {
    setPassConfirmVisibility(!passConfirmVisibility);
  }

  return (
    <Container>
      <TextField
        inputRef={inputs.email}
        error={!!validation.email}
        helperText={validation.email}
        label="E-mail"
        placeholder="Digite o e-mail"
        margin="normal"
        fullWidth
        value={user.email}
        onChange={e => handleChange('email', e.target.value)}
        autoComplete="none"
      />
      <TextField
        inputRef={inputs.name}
        error={!!validation.name}
        helperText={validation.name}
        label="Nome"
        placeholder="Digite o nome"
        margin="normal"
        fullWidth
        value={user.name}
        onChange={e => handleChange('name', e.target.value)}
      />

      <div className="passwords">
        <TextField
          inputRef={inputs.password}
          error={!!validation.password}
          helperText={validation.password}
          label="Nova senha"
          placeholder="Sua nova senha"
          margin="normal"
          onChange={e => handleChange('password', e.target.value)}
          value={user.password}
          type={passVisibility ? 'password' : 'text'}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {passVisibility ? (
                  <Visibility style={{ cursor: 'pointer' }} onClick={handlePasswordVisibility} color="primary" />
                ) : (
                  <VisibilityOff style={{ cursor: 'pointer' }} onClick={handlePasswordVisibility} color="primary" />
                )}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          inputRef={inputs.password_confirmation}
          error={!!validation.password_confirmation}
          helperText={validation.password_confirmation}
          label="Nova senha"
          placeholder="Repita a nova senha"
          margin="normal"
          onChange={e => handleChange('password_confirmation', e.target.value)}
          value={user.password_confirmation}
          type={passConfirmVisibility ? 'password' : 'text'}
          autoComplete="password-confirm"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {passConfirmVisibility ? (
                  <Visibility style={{ cursor: 'pointer' }} onClick={handlePasswordConfirmVisibility} color="primary" />
                ) : (
                  <VisibilityOff
                    style={{ cursor: 'pointer' }}
                    onClick={handlePasswordConfirmVisibility}
                    color="primary"
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
      </div>
    </Container>
  );
};

export default UserForm;
