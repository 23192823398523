import { Done } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

interface NewCustomerAppbarButtonsProps {
  handleValidation(): void;
}

const NewCustomerAppbarButtons: React.FC<NewCustomerAppbarButtonsProps> = ({ handleValidation }) => {
  return (
    <div>
      <IconButton color="inherit" onClick={handleValidation}>
        <Done />
      </IconButton>
    </div>
  );
};

export default NewCustomerAppbarButtons;
