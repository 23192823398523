import React from 'react';
import { useSidebar } from '../hook/useSidebar';
import SidebarItem from '../SidebarItem';
import SidebarItemExpendable from '../SidebarItemExpendable';
import SidebarItemMore from '../SidebarItemMore';
import { Collapse, List } from '@mui/material';
import { AdminPanelSettings, Computer, Settings as SettingsIcon, Image } from '@mui/icons-material';

const Settings: React.FC = () => {
  const { handleCollapseClick, collapsible, setShownUserControl, setImageManager } = useSidebar();
  return (
    <>
      <SidebarItemExpendable
        onClick={() => handleCollapseClick('settings')}
        text="Configurações"
        opened={collapsible.settings}
        icon={<SettingsIcon />}
      />
      <Collapse in={collapsible.settings}>
        <List disablePadding>
          <SidebarItem to="/settings" icon={<AdminPanelSettings />} text="Painel de Controle" nested={5} />

          <SidebarItemMore
            icon={<Computer />}
            handleClick={() => setShownUserControl(true)}
            text="Controle de usuário"
            nested={5}
          />

          <SidebarItem
            text="Imagens"
            icon={<Image />}
            onClick={() => setImageManager(true)}
            isLink={false}
            nested={5}
          />

          <SidebarItem icon={<Image />} to="/banners" text="Banners" nested={5} />
        </List>
      </Collapse>
    </>
  );
};

export default Settings;
