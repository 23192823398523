import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { api } from 'services/api';
import { Customer } from 'types/customer';

type UseFetchCustomer = {
  customer: Customer | null;
  setCustomer: Dispatch<SetStateAction<Customer | null>>;
  loading: boolean;
  error: string;
};

export function useFetchCustomer(customerId: string | undefined): UseFetchCustomer {
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!customerId) {
      return;
    }

    setLoading(true);

    api
      .get(`/users/${customerId}`)
      .then(response => {
        setCustomer(response.data);
      })
      .catch(err => {
        console.error(err);
        setError('Não foi possível carregar os dados do cliente');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [customerId]);

  return {
    customer,
    setCustomer,
    loading,
    error,
  };
}
