import React from 'react';
import { Typography } from '@mui/material';
import { CustomerInfo } from 'types/customer';
import { customerTableTemplate } from 'pages/customers/customerTableTemplate';

type CustomerItemTableProps = {
  customer: CustomerInfo;
};

const CustomerItemTable: React.FC<CustomerItemTableProps> = ({ customer }) => {
  return (
    <>
      {customerTableTemplate.map(item => (
        <div key={item.id}>
          <Typography variant="body2">{customer[item.id]}</Typography>
        </div>
      ))}
    </>
  );
};

export default CustomerItemTable;
