import React, { FormEvent, useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useParams } from 'react-router-dom';
import BlancaLuzInstallmentActions from './BlancaLuzInstallmentActions';
import BlancaLuzInstallmentForm from '../BlancaLuzInstallmentForm';
import { useBlancaLuzInstallmentValidation } from '../validation/blancaLuzInstallmentValidation';
import { history } from 'services/history';
import { api } from 'services/api';
import { useMessaging } from 'providers/messaging';
import { BlancaLuzInstallment } from 'types/blancaLuzInstallment';

const BlancaLuzInstallmentUpdate: React.FC = () => {
  const [installment, setInstallment] = useState<BlancaLuzInstallment>({} as BlancaLuzInstallment);
  const [validation, setValidation, validate] = useBlancaLuzInstallmentValidation();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { handleOpen } = useMessaging();

  useEffect(() => {
    api
      .get(`/installments/${id}`)
      .then(response => {
        setInstallment(response.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function handleChange(index: keyof BlancaLuzInstallment, value: any) {
    setInstallment(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(installment)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/installments/${id}`, installment)
      .then(() => {
        setSaving(false);
        history.push('/blanca-luz-installments');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="BLZ Cred Parcelas"
        ActionsComponent={
          <BlancaLuzInstallmentActions saving={saving} loading={loading} handleSubmit={handleValidation} />
        }
      />
      <PageHeader title="Editar parcela" backUrl="/blanca-luz-installments" />
      {loading ? (
        <InsideLoading />
      ) : (
        <form onSubmit={handleValidation}>
          <BlancaLuzInstallmentForm handleChange={handleChange} installment={installment} validation={validation} />
        </form>
      )}
    </>
  );
};

export default BlancaLuzInstallmentUpdate;
