import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';

const useStyles = makeStyles({
  loading: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1400,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#30519f',
  },
  logo: {
    width: 180,
  },
});

const SplashScreenContent: React.FC = () => {
  const classes = useStyles();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <div className={classes.loading}>
      <img
        className={classes.logo}
        src="https://h2i.s3.sa-east-1.amazonaws.com/upload/images/10043820210217602d14662205f.png"
      />
    </div>
  );
};

export default SplashScreenContent;
