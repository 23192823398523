import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { BlancaLuzLoan } from 'types/blancaLuzLoan';

type WaitingLoanContextValue = {
  selectedLoan: BlancaLuzLoan | null;
  setSelectedLoan: Dispatch<SetStateAction<BlancaLuzLoan | null>>;
};

const WaitingLoanContext = createContext<WaitingLoanContextValue>({} as WaitingLoanContextValue);
export const WaitingLoanProvider = WaitingLoanContext.Provider;

export function useWaitingLoan(): WaitingLoanContextValue {
  const context = useContext(WaitingLoanContext);
  return context;
}
