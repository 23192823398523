import React, { useReducer, FormEvent, useState, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import UserForm from './UserForm';
import userReducer, { USER_INITIAL_STATE } from '../reducer/reducer';
import { userChange, setUser } from '../reducer/actions';
import UserActions from './UserActions';
import * as yup from 'yup';
import { api } from 'services/api';
import { history } from 'services/history';
import Loading from 'components/loading/Loading';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import UserDeleteConfirm from './UserDeleteConfirm';
import { useMessaging } from 'providers/messaging';
import PageHeader from 'components/page-header/PageHeader';

export type UserValidation = {
  username?: string;
  email?: string;
  name?: string;
};

const EditUser: React.FC = () => {
  const [user, dispatch] = useReducer(userReducer, USER_INITIAL_STATE);
  const [validation, setValidation] = useState<UserValidation>({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();
  const [userDeleteConfirm, setUserDeleteConfirm] = useState(false);

  useEffect(() => {
    if (!id) return;

    async function loadUser() {
      const response = await api.get(`admin-users/${id}`);
      dispatch(setUser(response.data));
    }

    setLoading(true);

    Promise.all([loadUser()])
      .catch(() => {
        handleOpen('Não foi possível carregar o usuário');
        history.push('/users');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleOpen, id]);

  function handleChange(index: string, value: any) {
    dispatch(userChange(index, value));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
      email: yup.string().email('Informe um e-mail válido').required('Informe o email'),
    });

    schema
      .validate(user)
      .then(() => {
        handleSubmit();
        setValidation({});
      })
      .catch(err => {
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/admin-users/${id}`, user)
      .then(() => {
        handleOpen('Salvo');
        setLoading(false);
        history.push('/users');
      })
      .catch(err => {
        setSaving(false);
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível salvar o usuário');
      });
  }

  function handleDelete() {
    api
      .delete(`/users/${id}`)
      .then(() => {
        history.push('/users');
        handleOpen('Usuário excluído');
        setLoading(true);
      })
      .catch(err => {
        setLoading(false);
        handleOpen(err.response ? err.response.data.error : 'Não foi possível excluir o usuário');
      });
  }

  return (
    <>
      <Appbar
        backAction={() => history.push('/users')}
        title="Editar usuário"
        ActionsComponent={
          <UserActions handleSubmit={handleValidation} handleDelete={() => setUserDeleteConfirm(true)} />
        }
      />

      <PageHeader title="Alteração de usuário" backUrl="/users" />

      {saving && <Loading />}

      {userDeleteConfirm && (
        <UserDeleteConfirm handleDelete={handleDelete} onExited={() => setUserDeleteConfirm(false)} />
      )}

      {loading ? (
        <InsideLoading />
      ) : (
        <form onSubmit={handleValidation}>
          <UserForm handleChange={handleChange} user={user} validation={validation} />
          <button type="submit" style={{ display: 'none' }} />
        </form>
      )}
    </>
  );
};

export default EditUser;
