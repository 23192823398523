import React from 'react';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { makeStyles } from '@mui/styles';
import { history } from 'services/history';
import { Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { CustomerInfo } from 'types/customer';
import CustomerItemTable from './CustomerItemTable';
import { useTable } from 'components/table/hook/useTable';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type CustomerListTableProps = {
  customers: CustomerInfo[];
  handleSort(id: string): void;
  orderedIndex: OrderIndexData;
};

const CustomerListTable: React.FC<CustomerListTableProps> = ({ customers, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { page, rowsPerPage } = usePagination();
  const { tableTemplate } = useTable();

  function handleClick(id?: string) {
    history.push(`/customers/${id}`);
  }

  return (
    <>
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <div
              className={classes.headerItem}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(customer => (
            <TableRow key={customer.id} onClick={() => handleClick(customer.user_id)}>
              <CustomerItemTable customer={customer} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default CustomerListTable;
