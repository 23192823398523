import { Dispatch, SetStateAction, useState } from 'react';
import { City } from 'types/city';
import * as yup from 'yup';

export type CityValidation = {
  state?: string;
  name?: string;
  ibge_code?: string;
};

type UseCityValidation = [CityValidation, Dispatch<SetStateAction<CityValidation>>, (city: City) => Promise<void>];

export function useCityValidation(): UseCityValidation {
  async function handleValidation(city: City) {
    const schema = yup.object().shape({
      ibge_code: yup.string().required('O código IBGE é obrigatório'),
      state: yup.string().required('O estado é obrigatório'),
      name: yup.string().required('O nome é obrigatório'),
    });

    try {
      await schema.validate(city);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path) {
        setValidation({
          [error.path]: error.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<CityValidation>({});
  return [validation, setValidation, handleValidation];
}
