import {
  AccountBalance,
  Add,
  DeleteForever,
  HourglassEmpty,
  LocalAtm,
  LocationOn,
  People,
  ThumbUpAlt,
} from '@mui/icons-material';
import { Collapse } from '@mui/material';
import React from 'react';
import { useSidebar } from '../hook/useSidebar';
import SidebarItem from '../SidebarItem';
import SidebarItemExpendable from '../SidebarItemExpendable';

const BlzCred: React.FC = () => {
  const { handleCollapseClick, collapsible } = useSidebar();

  return (
    <div>
      <SidebarItemExpendable
        onClick={() => handleCollapseClick('blancaLuzRegistration', false)}
        icon={<Add />}
        text="Cadastro"
        opened={collapsible.blancaLuzRegistration}
      />
      <Collapse in={collapsible.blancaLuzRegistration}>
        <SidebarItem to="/customers" icon={<People />} text="Clientes" nested={5} />
      </Collapse>
      <Collapse in={collapsible.blancaLuzRegistration}>
        <SidebarItem to="/blanca-luz-installments" icon={<LocalAtm />} text="Parcelas" nested={5} />
      </Collapse>
      <Collapse in={collapsible.blancaLuzRegistration}>
        <SidebarItem to="/cities" icon={<LocationOn />} text="Municípios" nested={5} />
      </Collapse>
      <SidebarItemExpendable
        onClick={() => handleCollapseClick('blancaLuzLoan', false)}
        icon={<AccountBalance />}
        text="Empréstimos"
        opened={collapsible.blancaLuzLoan}
      />
      <Collapse in={collapsible.blancaLuzLoan}>
        <SidebarItem to="/blanca-luz-loan" icon={<ThumbUpAlt />} text="Contratados" nested={5} />
        <SidebarItem to="/waiting-loan" icon={<HourglassEmpty />} text="Aguardando contratação" nested={5} />
        <SidebarItem to="/failed-loan" icon={<DeleteForever />} text="Cancelados" nested={5} />
      </Collapse>
    </div>
  );
};

export default BlzCred;
