import { TableTemplate } from 'types/tableTemplate';

export const blancaLuzInstallmentsTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 100,
  },
  {
    id: 'installment',
    description: 'PARCELA',
    originalId: 'installment',
    width: 200,
  },
  {
    id: 'formattedFee',
    description: 'JUROS',
    originalId: 'fee',
    width: 100,
  },
  {
    id: 'formattedDailyIof',
    description: 'IOF DIÁRIO',
    originalId: 'daily_iof',
    width: 100,
  },
  {
    id: 'formattedAdditionalIof',
    description: 'IOF ADICIONAL',
    originalId: 'additional_iof',
    width: 100,
  },
  {
    id: 'formattedFactor',
    description: 'FATOR',
    originalId: 'factor',
    width: 100,
  },
  {
    id: 'due_days',
    description: 'VENCIMENTO',
    originalId: 'due_days',
    width: 120,
  },
  {
    id: 'formattedForSimplesNacional',
    description: 'SIMPLES NACIONAL',
    originalId: 'for_simples_nacional',
    width: 150,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'formattedCreatedAt',
    width: 150,
    notSearchable: true,
  },
];
