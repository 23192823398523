import React, { useEffect, useRef, useState } from 'react';
import { BannerValidation } from './validation/bannerValidation';
import ImageSelector from 'components/image-selector/ImageSelector';
import ImagePreview from 'components/image-preview/ImagePreview';
import ImageUpload from 'components/image-upload/ImageUpload';
import { FormControlLabel, Grid, Switch, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Banner } from 'types/banner';

const useStyles = makeStyles((theme: Theme) => ({
  switch: {
    marginTop: 20,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '400px 1fr',
    marginBottom: 200,
    gap: 40,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gap: 10,
    },
  },
  imageContainer: {
    marginTop: 20,
  },
}));

type BannerFormProps = {
  banner: Banner;
  handleChange(index: keyof Banner, value: any): void;
  validation: BannerValidation;
};

const BannerForm: React.FC<BannerFormProps> = ({ banner, handleChange, validation }) => {
  const classes = useStyles();

  const [imageSelector, setImageSelector] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<null | string>('');

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    title: useRef<HTMLInputElement>(null),
    subtitle: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
    order: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      {!!selectedImageSrc && (
        <ImagePreview
          src={selectedImageSrc}
          description="Imagem da mensagem de e-mail"
          onExited={() => setSelectedImageSrc('')}
        />
      )}
      {imageSelector && (
        <ImageSelector
          handleSetImageId={image => handleChange('image', image)}
          onExited={() => setImageSelector(false)}
        />
      )}
      <div className={classes.container}>
        <div>
          <ImageUpload
            validationError={validation.image}
            label="Imagem desktop 600 x 200"
            handleSetImage={image => handleChange('image', image)}
            handleRemoveImage={() => handleChange('image', null)}
            image={banner.image}
            containerClassname={classes.imageContainer}
          />

          <ImageUpload
            validationError={validation.image}
            label="Imagem mobile 300 x 200"
            handleSetImage={image => handleChange('mobile_image', image)}
            handleRemoveImage={() => handleChange('mobile_image', null)}
            image={banner.mobile_image}
            containerClassname={classes.imageContainer}
          />
        </div>
        <Grid item xs={12} xl={4} lg={4} md={6}>
          <TextField
            inputRef={inputs.name}
            error={!!validation.name}
            helperText={validation.name}
            label="Nome"
            placeholder="Digite um nome para o banner"
            margin="normal"
            fullWidth
            value={banner.name}
            onChange={e => handleChange('name', e.target.value)}
            autoFocus
          />

          <TextField
            inputRef={inputs.description}
            error={!!validation.description}
            helperText={validation.description}
            label="Descrição"
            placeholder="Informe a descrição"
            margin="normal"
            fullWidth
            value={banner.description}
            onChange={e => handleChange('description', e.target.value)}
          />

          <FormControlLabel
            label="Ativo"
            className={classes.switch}
            control={
              <Switch
                color="primary"
                onChange={event => handleChange('active', event.target.checked)}
                checked={banner.active}
              />
            }
          />

          <button type="submit" style={{ display: 'none' }} />
        </Grid>
      </div>
    </>
  );
};

export default BannerForm;
