import { createContext, useContext } from 'react';
import { Banner } from 'types/banner';

type BannersContextValue = {
  selectedBanner: Banner | null;
  setSelectedBanner(banner: Banner | null): void;
};

const BannerContext = createContext<BannersContextValue>({} as BannersContextValue);
export const BannersProvider = BannerContext.Provider;

export function useBanners(): BannersContextValue {
  const context = useContext(BannerContext);
  return context;
}
