import { Person, Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { LoginValidation } from './Login';

type LoginFormProps = {
  email: string;
  password: string;
  setEmail(email: string): void;
  setPassword(password: string): void;
  validation: LoginValidation;
};

const LoginForm: React.FC<LoginFormProps> = ({ email, password, setEmail, setPassword, validation }) => {
  const [passVisibility, setPassVisibility] = useState(true);
  const inputs = {
    email: useRef<HTMLInputElement>(null),
    password: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  function handlePasswordVisibility() {
    setPassVisibility(!passVisibility);
  }

  return (
    <>
      <TextField
        inputRef={inputs.email}
        error={!!validation.email}
        helperText={validation.email}
        label="E-mail"
        placeholder="Seu e-mail"
        margin="normal"
        onChange={e => setEmail(e.target.value)}
        value={email}
        autoComplete="e-mail"
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Person color="primary" />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        inputRef={inputs.password}
        error={!!validation.password}
        helperText={validation.password}
        label="Senha"
        placeholder="Sua senha"
        margin="normal"
        onChange={e => setPassword(e.target.value)}
        value={password}
        type={passVisibility ? 'password' : 'text'}
        autoComplete="current-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {passVisibility ? (
                <Visibility style={{ cursor: 'pointer' }} onClick={handlePasswordVisibility} color="primary" />
              ) : (
                <VisibilityOff style={{ cursor: 'pointer' }} onClick={handlePasswordVisibility} color="primary" />
              )}
            </InputAdornment>
          ),
        }}
      />
      <button type="submit" style={{ display: 'none' }} />
    </>
  );
};

export default LoginForm;
