import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { BlancaLuzLoan } from 'types/blancaLuzLoan';
import { MoreHoriz } from '@mui/icons-material';
import { useFailedLoan } from '../../hooks/useFailedLoan';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
});

type FailedLoanItemTableProps = {
  loan: BlancaLuzLoan;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const FailedLoanItemTable: React.FC<FailedLoanItemTableProps> = ({ loan, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedLoan } = useFailedLoan();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedLoan(loan);
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? classes.numericData : undefined}>
              <Typography variant="body2">{loan[item.id]}</Typography>
            </div>
          )
        )}
    </>
  );
};

export default FailedLoanItemTable;
