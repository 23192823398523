import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Customer } from 'types/customer';

interface CustomerItemProps {
  customer: Customer;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '20px',
    rowGap: '40px',
    flex: 1,
    marginTop: 20,
    marginBottom: 100,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      rowGap: '0px',
    },
    '& > div': {
      [theme.breakpoints.down('sm')]: {
        marginTop: 40,
      },
    },
  },
  containerDirection: {
    display: 'flex',
    gap: 30,
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      gap: 10,
    },
  },
}));

const CustomerItem: React.FC<CustomerItemProps> = ({ customer }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <Typography variant="subtitle2">Primeiro nome</Typography>
        <Typography variant="caption" color="textSecondary">
          Este é o primeiro nome do cliente
        </Typography>
      </div>
      <Typography variant="caption" fontWeight={600}>
        {customer.first_name}
      </Typography>

      <div>
        <Typography variant="subtitle2">Último nome</Typography>
        <Typography variant="caption" color="textSecondary">
          Este é o último nome do cliente
        </Typography>
      </div>
      <Typography variant="caption" fontWeight={600}>
        {customer.last_name}
      </Typography>

      <div>
        <Typography variant="subtitle2">Documento</Typography>
        <Typography variant="caption" color="textSecondary">
          Este é o documento do cliente
        </Typography>
      </div>
      <Typography variant="caption" fontWeight={600}>
        {customer.customer.document_type.toUpperCase()} - {customer.customer.document_number}
      </Typography>

      <div>
        <Typography variant="subtitle2">Nome da empresa</Typography>
        <Typography variant="caption" color="textSecondary">
          Este é o nome da empresa
        </Typography>
      </div>
      <Typography variant="caption" fontWeight={600}>
        {customer.customer.company_name}
      </Typography>

      <div>
        <Typography variant="subtitle2">Fantasia</Typography>
      </div>
      <Typography variant="caption" fontWeight={600}>
        {customer.customer.trade_name}
      </Typography>

      <div>
        <Typography variant="subtitle2">E-mail</Typography>
        <Typography variant="caption" color="textSecondary">
          Este é o e-mail do cliente
        </Typography>
      </div>
      <Typography variant="caption" fontWeight={600}>
        {customer.email}
      </Typography>

      <div>
        <Typography variant="subtitle2">Telefone</Typography>
        <Typography variant="caption" color="textSecondary">
          Este é o telefone do cliente
        </Typography>
      </div>
      <Typography variant="caption" fontWeight={600}>
        Telefone - {customer.customer.phone}
      </Typography>

      <div>
        <Typography variant="subtitle2">Endereço do cliente</Typography>
        <Typography variant="caption" color="textSecondary">
          Informações sobre o endereço
        </Typography>
      </div>
      <div className={classes.containerDirection}>
        <Typography variant="caption" fontWeight={600}>
          CEP - {customer.customer.postal_code}
        </Typography>

        <Typography variant="caption" fontWeight={600}>
          Rua - {customer.customer.street}
        </Typography>

        <Typography variant="caption" fontWeight={600}>
          Nº - {customer.customer.street_number}
        </Typography>

        <Typography variant="caption" fontWeight={600}>
          Estado - {customer.customer.state}
        </Typography>

        <Typography variant="caption" fontWeight={600}>
          Cidade - {customer.customer.city}
        </Typography>

        <Typography variant="caption" fontWeight={600}>
          Complemento - {customer.customer.complement}
        </Typography>
      </div>
    </div>
  );
};

export default CustomerItem;
