import { TextField, styled } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Customer } from 'types/customer';
import { CustomerValidation } from './validation/customerValidation';
import { useCustomer } from './hooks/useCustomer';
import CpfInput from 'components/masked-input/CpfInput';
import MaskedInput from 'react-text-mask';

const Container = styled('div')({
  display: 'flex',
  maxWidth: 600,
  flexDirection: 'column',
  marginTop: 30,
  '& .two-columns': {
    display: 'flex',
    gap: 20,
    '& > div': {
      flex: 3,
    },
    '& > div:last-child': {
      flex: 1,
    },
  },
});

interface CustomerFormProps {
  customer: Customer | null;
  validation: CustomerValidation;
}

const CustomerForm: React.FC<CustomerFormProps> = ({ customer, validation }) => {
  const { handleUserChange } = useCustomer();

  const inputs = {
    first_name: useRef<HTMLInputElement>(null),
    last_name: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    document_number: useRef<MaskedInput>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    if (key === 'document_number') {
      inputs.document_number.current?.inputElement.focus();
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line
  return (
    <Container>
      <TextField
        inputRef={inputs.email}
        label="E-mail"
        placeholder="Informe o e-mail do cliente"
        margin="normal"
        fullWidth
        helperText={validation.email}
        error={!!validation.email}
        value={customer?.email}
        onChange={event => handleUserChange('email', event.target.value)}
        disabled
      />

      <TextField
        inputRef={inputs.first_name}
        label="Nome"
        placeholder="Informe o nome"
        margin="normal"
        fullWidth
        helperText={validation.first_name}
        error={!!validation.first_name}
        value={customer?.first_name}
        onChange={event => handleUserChange('first_name', event.target.value)}
        autoFocus
      />

      <TextField
        inputRef={inputs.last_name}
        label="Sobrenome"
        placeholder="Informe o sobrenome"
        margin="normal"
        fullWidth
        helperText={validation.last_name}
        error={!!validation.last_name}
        value={customer?.last_name}
        onChange={event => handleUserChange('last_name', event.target.value)}
      />

      <TextField
        label="CPF"
        placeholder="Informe o CPF"
        margin="normal"
        fullWidth
        helperText={validation.document_number}
        error={!!validation.document_number}
        value={customer?.document_number}
        inputRef={inputs.document_number}
        onChange={event => handleUserChange('document_number', event.target.value)}
        InputProps={{
          inputComponent: CpfInput as any,
        }}
      />
    </Container>
  );
};

export default CustomerForm;
