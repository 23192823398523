import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export type LoginValidation = {
  email?: string;
  password?: string;
};

type LoginPayload = {
  email: string;
  password: string;
};

type UseLoginValidation = [
  LoginValidation,
  Dispatch<SetStateAction<LoginValidation>>,
  (payload: LoginPayload) => Promise<void>
];

export function useLoginValidation(): UseLoginValidation {
  async function handleValidation(payload: LoginPayload) {
    const schema = yup.object().shape({
      email: yup.string().required('O slug é obrigatório'),
      password: yup.string().required('As keywords são obrigatórias'),
    });

    try {
      await schema.validate(payload);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(error.message);
    }
  }

  const [validation, setValidation] = useState<LoginValidation>({});
  return [validation, setValidation, handleValidation];
}
