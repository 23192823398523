import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Done } from '@mui/icons-material';

interface MobileSearchActionsProps {
  handleClose(): void;
}

const MobileSearchActions: React.FC<MobileSearchActionsProps> = ({ handleClose }) => {
  return (
    <Tooltip title="Buscar">
      <IconButton onClick={handleClose} color="inherit">
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default MobileSearchActions;
