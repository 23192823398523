import React, { useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import { api, getCancelTokenSource } from 'services/api';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import useTableOrder from 'hooks/tableOrder';
import useSearch from 'hooks/search';
import { Button, Grid, InputAdornment, TextField, Theme, Typography } from '@mui/material';
import { useApp } from 'hooks/useApp';
import UserActions from './UserActions';
import { history } from 'services/history';
import { makeStyles } from '@mui/styles';
import { Search } from '@mui/icons-material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import TableLoading from 'components/loading/TableLoading';
import UsersCardLoading from './card/UsersCardLoading';
import UserListTable from './table/UserList';
import UserListCard from './card/UserList';
import PaginationProvider from 'hooks/pagination';
import ApiPagination from 'components/pagination/ApiPagination';
import { AdminUser } from 'types/admin-user';

const useStyles = makeStyles((theme: Theme) => ({
  bottomActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  options: {
    backgroundColor: '#fff',
    borderRadius: '8px 8px 0 0',
    padding: 10,
    justifyContent: 'space-between',
  },
  noUsers: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    marginTop: 10,
  },
}));

const NoUsers: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.noUsers}>
      <Typography variant="h6" color="textSecondary">
        Nenhum usuário
      </Typography>
    </div>
  );
};

const Users: React.FC = () => {
  const classes = useStyles();
  const app = useApp();
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const [users, setUsers] = useState<AdminUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filteredUsers, setFilteredUsers] = useState<AdminUser[]>([]);
  const [indexFiltered, sort] = useTableOrder();
  const search = useSearch();

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    api
      .get<AdminUser[]>('/admin-users', { cancelToken: source.token, params: { page: page + 1, rows } })
      .then(response => {
        if (request) {
          const _users = response.data.map(user => ({
            ...user,
          }));
          setUsers(_users);
          setFilteredUsers(_users);
        }
      })
      .finally(() => {
        if (request) setLoading(false);
        request = false;
      });

    return () => {
      if (request) request = false;

      source.cancel();
    };
  }, [page, rows]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSearch(searchValue: string) {
    if (searchValue) {
      const p = search(searchValue, 'name', users);
      setFilteredUsers(p);
      return;
    }

    setFilteredUsers(users);
  }

  function handleSort(id: string) {
    setFilteredUsers(sort(id, filteredUsers));
  }

  return (
    <>
      <Appbar title="Configurações" ActionsComponent={<UserActions />} />
      <PageHeaderActions
        title="Usuários"
        ActionComponent={
          <Button color="primary" variant="contained" size="small" onClick={() => history.push('/user')}>
            Adicionar
          </Button>
        }
      />
      <Grid container className={classes.options}>
        <Grid item xs={app.isMobile || app.windowWidth < 960 ? 12 : 4}>
          <TextField
            fullWidth
            label="Pesquisar"
            placeholder="Digite sua pesquisa"
            autoFocus
            onChange={e => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <div className={classes.bottomActions}>
          <DisplayModeButtons
            displayMode={displayMode}
            handleShowList={() => setDisplayMode('list')}
            handleShowModule={() => setDisplayMode('module')}
          />
        </div>
      </Grid>
      <PaginationProvider>
        {displayMode === 'list' ? (
          loading ? (
            <TableLoading />
          ) : filteredUsers.length > 0 ? (
            <UserListTable users={filteredUsers} handleSort={handleSort} orderedIndex={indexFiltered} />
          ) : (
            <NoUsers />
          )
        ) : loading ? (
          <UsersCardLoading />
        ) : filteredUsers.length > 0 ? (
          <UserListCard users={filteredUsers} />
        ) : (
          <NoUsers />
        )}
        <ApiPagination
          onChangePage={value => setPage(value)}
          onChangeRowsPerPage={value => setRows(value)}
          count={filteredUsers.length}
        />
      </PaginationProvider>
    </>
  );
};

export default Users;
