export const customerTableTemplate = [
  {
    id: 'trade_name',
    description: 'Fantasia',
    originalId: 'trade_name',
    width: 300,
  },
  {
    id: 'company_name',
    description: 'Razão social',
    originalId: 'company_name',
    width: 300,
  },
  {
    id: 'document_number',
    description: 'Documento',
    originalId: 'document_number',
    width: 300,
  },
];
