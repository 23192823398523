import { User, UserActionsType, USER_CHANGE, SET_USER } from './types';

export const USER_INITIAL_STATE: User = {
  name: '',
  email: '',
  password: '',
  password_confirmation: '',
};

export default function userReducer(state = USER_INITIAL_STATE, action: UserActionsType): User {
  switch (action.type) {
    case USER_CHANGE: {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case SET_USER: {
      return action.user;
    }

    default: {
      return state;
    }
  }
}
