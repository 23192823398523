import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useBlancaLuzLoan } from '../../hooks/useBlancaLuzLoan';

type LoanListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const LoanListMenu: React.FC<LoanListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedLoan } = useBlancaLuzLoan();

  function handleClick() {
    setAnchorEl(null);
    window.open(`${selectedLoan?.contract_download_url}`);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleClick}>Download contrato</MenuItem>
    </Menu>
  );
};

export default LoanListMenu;
