import { TableTemplate } from 'types/tableTemplate';

export const waitingLoanTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 100,
    notFilterable: true,
  },
  {
    id: 'customer_representative_name',
    description: 'NOME',
    originalId: 'customer_representative_name',
    width: 200,
  },
  {
    id: 'installment_quantity',
    description: 'QTD PARCELAS',
    originalId: 'installment_quantity',
    dataType: 'number',
    width: 120,
  },
  {
    id: 'formattedInstallmentValue',
    description: 'VALOR PARCELAS',
    dataType: 'number',
    originalId: 'installment_value',
    width: 150,
  },
  {
    id: 'formattedValue',
    description: 'VALOR',
    dataType: 'number',
    originalId: 'value',
    width: 170,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'formattedCreatedAt',
    width: 150,
    notSearchable: true,
  },
];
