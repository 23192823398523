import { Dispatch, SetStateAction, useState } from 'react';
import { Banner } from 'types/banner';
import * as yup from 'yup';

export type BannerValidation = {
  name?: string;
  image?: string;
  description?: string;
};

type UseBannerValidation = [
  BannerValidation,
  Dispatch<SetStateAction<BannerValidation>>,
  (banner: Banner) => Promise<void>
];

export function useBannerValidation(): UseBannerValidation {
  async function handleValidation(banner: Banner) {
    const schema = yup.object().shape({
      image: yup.object().required('O banner é obrigatório'),
      description: yup.string().required('A descrição é obrigatória'),
      name: yup.string().required('O nome do banner é obrigatória'),
    });

    try {
      await schema.validate(banner);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path) {
        setValidation({
          [error.path]: error.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<BannerValidation>({});
  return [validation, setValidation, handleValidation];
}
