import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import TableContainer from 'components/table/TableContainer';
import { format, parseISO, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import React, { useCallback, useEffect, useState } from 'react';
import { api } from 'services/api';
import BannersFilterBox from './BannersFilterBox';
import { bannersTableTemplate } from './bannersTableTemplate';
import BannerListModule from './list/module/BannerListModule';
import BannerListTable from './list/table/BannerListTable';
import SiteBannersActions from './BannersActions';
import { Banner } from 'types/banner';
import { makeStyles } from '@mui/styles';
import { Button, Theme } from '@mui/material';
import { useApp } from 'hooks/useApp';
import { history } from 'services/history';
import { BannersProvider } from './hooks/useBanners';
import ApiPagination from 'components/pagination/ApiPagination';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

export interface QueryParams {
  initial_date: Date | null;
  final_date: Date | null;
  active: boolean;
  name: string;
}

const queryParamsInitialValue: QueryParams = {
  initial_date: subDays(new Date(), 365),
  final_date: new Date(),
  active: true,
  name: '',
};

let timer: NodeJS.Timeout;

const Banners: React.FC = () => {
  const classes = useStyles();
  const [banners, setBanners] = useState<Banner[]>([]);
  const [selectedBanner, setSelectedBanner] = useState<null | Banner>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Banner[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const app = useApp();
  const [queryParams, setQueryParams] = useState<QueryParams>(queryParamsInitialValue);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);

  const fetchBanners = useCallback(
    (query?: QueryParams) => {
      setLoading(true);
      api
        .get<Banner[]>('/banners', {
          params: { ...query, active: query?.active ? 1 : 0, page: page + 1, rows },
        })
        .then(response => {
          setBanners(
            response.data.map(item => {
              item.formattedCreatedAt = format(parseISO(item.created_at), 'PPp', { locale: ptBR });
              item.formattedActive = item.active ? 'Sim' : 'Não';
              return item;
            })
          );
        })
        .catch(err => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    },
    [page, rows]
  );

  useEffect(() => {
    setFiltered(banners);
  }, [banners]);

  useEffect(() => {
    fetchBanners(queryParamsInitialValue);
  }, [fetchBanners]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleQueryParamsChange(index: keyof QueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));

    const query = {
      ...queryParams,
      [index]: value,
    };

    clearTimeout(timer);

    if (index !== 'name') {
      fetchBanners(query);
      return;
    }

    if (index === 'name' && value.lenght < 4) {
      return;
    }

    timer = setTimeout(() => fetchBanners(query), 500);
  }

  return (
    <BannersProvider value={{ selectedBanner, setSelectedBanner }}>
      <Appbar title="Site" ActionsComponent={<SiteBannersActions />} />
      <PageHeaderActions
        title="Banners"
        description="Gestão dos banners"
        ActionComponent={
          <>
            <Button size="small" variant="contained" color="primary" onClick={() => history.push('/banner')}>
              Adicionar
            </Button>
          </>
        }
      />
      <TableContainer tableTemplate={bannersTableTemplate}>
        <BannersFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          handleQueryParamsChange={handleQueryParamsChange}
          queryParams={queryParams}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum banner para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <BannerListTable banners={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <BannerListModule banners={filtered} />
              )}
              <ApiPagination
                onChangePage={value => setPage(value)}
                onChangeRowsPerPage={value => setRows(value)}
                count={filtered.length}
              />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </BannersProvider>
  );
};

export default Banners;
