import React from 'react';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { CustomerInfo } from 'types/customer';
import CustomerItemModule from './CustomerItemModule';

type CustomerListModuleProps = {
  customers: CustomerInfo[];
};

const CustomerListModule: React.FC<CustomerListModuleProps> = ({ customers }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {customers.map(customer => (
            <CustomerItemModule key={customer.id} customer={customer} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default CustomerListModule;
