import { Dispatch, SetStateAction, useState } from 'react';
import { Customer } from 'types/customer';
import * as yup from 'yup';

export type CustomerValidation = {
  first_name?: string;
  last_name?: string;
  document_number?: string;
  email?: string;
  'customer.document_number'?: string;
  'customer.company_name'?: string;
  'customer.street'?: string;
  'customer.street_number'?: string;
  'customer.complement'?: string;
  'customer.neighborhood'?: string;
  'customer.state'?: string;
  'customer.city'?: string;
  'customer.postal_code'?: string;
  'customer.is_simples_nacional'?: boolean;
  'customer.trade_name'?: string;
  'customer.document_type'?: string;
  'customer.phone'?: string;
};

type UseCustomerValidation = [
  CustomerValidation,
  Dispatch<SetStateAction<CustomerValidation>>,
  (customer: Customer | null) => Promise<void>
];

export function useCustomerValidation(): UseCustomerValidation {
  async function handleValidation(customer: Customer | null) {
    const schema = yup.object().shape({
      first_name: yup.string().required('nome é obrigatório'),
      last_name: yup.string().required('sobrenome é obrigatório'),
      email: yup.string().email('deve ser informado um e-mail válido'),
      document_number: yup.string().required('CPF é obrigatório'),
      customer: yup.object().shape({
        city: yup.string().required('a cidade é obrigatória'),
        company_name: yup.string().required('a razão social é obrigatória'),
        complement: yup.string().nullable(),
        document_number: yup.string().required('o cnpj/cpf é obrigatório'),
        document_type: yup.string().required('o tipo do documento é obrigatório'),
        neighborhood: yup.string().required('o bairro é obrigatório'),
        phone: yup.string().required('o telefone é obrigatório'),
        postal_code: yup.string().required('o CEP é obrigatório'),
        state: yup.string().required('o estado é obrigatório'),
        street: yup.string().required('o endereço é obrigatório'),
        street_number: yup.string().required('o número é obrigatório'),
        trade_name: yup.string().required('o nome fantasia é obrigatório'),
      }),
    });

    try {
      await schema.validate(customer);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(error.message);
    }
  }

  const [validation, setValidation] = useState<CustomerValidation>({});
  return [validation, setValidation, handleValidation];
}
