import React, { useEffect, useState, useCallback, FormEvent } from 'react';
import Appbar from 'components/appbar/Appbar';
import { api, getCancelTokenSource } from 'services/api';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import useTableOrder from 'hooks/tableOrder';
import { IconButton, InputAdornment, MenuItem, TextField, Theme, Typography } from '@mui/material';
import { useApp } from 'hooks/useApp';
import { makeStyles } from '@mui/styles';
import { Search } from '@mui/icons-material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import TableLoading from 'components/loading/TableLoading';
import PaginationProvider from 'hooks/pagination';
import ApiPagination from 'components/pagination/ApiPagination';
import { Customer, CustomerInfo } from 'types/customer';
import CustomerListTable from './list/table/CustomerListTable';
import ModuleLoading from 'components/loading/ModuleLoading';
import CustomerListModule from './list/module/CustomerListModule';
import TableContainer from 'components/table/TableContainer';
import { customerTableTemplate } from './customerTableTemplate';
import useSearch from 'hooks/search';

const useStyles = makeStyles((theme: Theme) => ({
  bottomActions: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  options: {
    backgroundColor: '#fff',
    padding: 10,
    display: 'grid',
    gap: 10,
    alignItems: 'center',
    gridTemplateColumns: '400px 300px 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 100px',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  noUsers: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    marginTop: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const NoUsers: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.noUsers}>
      <Typography variant="h6" color="textSecondary">
        Nenhum usuário
      </Typography>
    </div>
  );
};

const Customers: React.FC = () => {
  const classes = useStyles();
  const app = useApp();
  const [page, setPage] = useState(0);
  const [term, setTerm] = useState('');
  const [rows, setRows] = useState(20);
  const [indexToSearch, setIndexToSearch] = useState('company_name');
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(false);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filteredCustomers, setFilteredCustomers] = useState<CustomerInfo[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const search = useSearch();

  useEffect(() => {
    setFilteredCustomers(customers.map(item => item.customer));
  }, [customers]);

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;
    setLoading(true);

    api
      .get<Customer[]>('/users', { cancelToken: source.token, params: { page: page + 1, rows } })
      .then(response => {
        if (request) {
          setCustomers(
            response.data.map(item => ({
              ...item,
              formattedDocument: item.customer.document_number,
              formattedTradeName: item.customer.trade_name,
            }))
          );
        }
      })
      .finally(() => {
        if (request) setLoading(false);
        request = false;
      });

    return () => {
      if (request) request = false;

      source.cancel();
    };
  }, [page, rows]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  const handleSearch = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      const f = search(
        term,
        indexToSearch,
        customers.map(item => item.customer)
      );
      setFilteredCustomers(f);
    },
    [customers, term, search, indexToSearch]
  );

  function handleSort(index: string) {
    const p = sort(index, filteredCustomers);
    setFilteredCustomers(p);
  }

  return (
    <>
      <Appbar title="Clientes" />
      <PageHeaderActions title="Clientes" />
      <form onSubmit={handleSearch} className={classes.options}>
        <TextField
          fullWidth
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          onChange={e => setTerm(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField value={indexToSearch} onChange={e => setIndexToSearch(e.target.value)} select label="Pesquisar em">
          <MenuItem value="company_name">Razão social</MenuItem>
          <MenuItem value="trade_name">Fantasia</MenuItem>
          <MenuItem value="document_number">CNPJ</MenuItem>
        </TextField>
        <div className={classes.bottomActions}>
          <DisplayModeButtons
            displayMode={displayMode}
            handleShowList={() => setDisplayMode('list')}
            handleShowModule={() => setDisplayMode('module')}
          />
        </div>
        <button type="submit" style={{ display: 'none' }}></button>
      </form>

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filteredCustomers.length === 0 ? (
        <NoUsers />
      ) : (
        <TableContainer tableTemplate={customerTableTemplate}>
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <CustomerListTable customers={filteredCustomers} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <CustomerListModule customers={filteredCustomers} />
              )}
              <ApiPagination
                onChangePage={value => setPage(value)}
                onChangeRowsPerPage={value => setRows(value)}
                count={filteredCustomers.length}
              />
            </div>
          </PaginationProvider>
        </TableContainer>
      )}
    </>
  );
};

export default Customers;
