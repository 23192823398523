import React from 'react';
import { makeStyles } from '@mui/styles';
import { ChevronRight } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText, Theme, useTheme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  listItemText: {
    fontSize: 14,
    color: '#fff',
  },
}));

interface SidebarItemMoreProps {
  text: string;
  icon: React.ReactElement;
  nested?: number;
  handleClick(): void;
}

const SidebarItemMore: React.FC<SidebarItemMoreProps> = ({ icon, text, handleClick, nested = 0 }) => {
  const classes = useStyles({ nested });
  const theme = useTheme();

  return (
    <ListItemButton
      style={{ paddingLeft: nested ? theme.spacing(nested) : 16, borderLeft: '2px solid transparent', minHeight: 50 }}
      onClick={handleClick}
    >
      <ListItemIcon style={{ color: '#fff' }}>{icon}</ListItemIcon>
      <ListItemText classes={{ primary: classes.listItemText }} primary={text} />
      <ChevronRight style={{ color: '#fff' }} />
    </ListItemButton>
  );
};

export default SidebarItemMore;
