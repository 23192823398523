import React, { useState, useEffect } from 'react';
import { Customer, CustomerInfo } from 'types/customer';
import { useCustomerValidation } from '../validation/customerValidation';
import { CustomerProvider } from '../hooks/useCustomer';
import { api } from 'services/api';
import { history } from 'services/history';
import Appbar from 'components/appbar/Appbar';
import NewCustomerAppbarButtons from './UpdateCustomerAppbarButtons';
import PageHeader from 'components/page-header/PageHeader';
import Loading from 'components/loading/Loading';
import CustomerCompanyForm from '../CustomerCompanyForm';
import CustomerUserForm from '../CustomerUserForm';
import { useParams } from 'react-router-dom';
import { useFetchCustomer } from '../hooks/useFetchCustomer';
import InsideLoading from 'components/loading/InsideLoading';
import CustomerTabs, { TabOptions } from '../CustomerTabs';

const UpdateCustomer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { customer, setCustomer, loading, error } = useFetchCustomer(id);
  const [validation, setValidation, validate] = useCustomerValidation();
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState<TabOptions>('user');

  useEffect(() => {
    const main = ['email', 'first_name', 'last_name', 'document_number'];

    const [key] = Object.keys(validation);

    if (!key) {
      return;
    }

    if (main.includes(key)) {
      setTab('user');
      return;
    }

    setTab('company');
  }, [validation]);

  function handleUserChange(index: keyof Customer, value: any) {
    setCustomer(state => {
      if (!state) {
        return null;
      }

      return {
        ...state,
        [index]: value,
      };
    });
  }

  function handleCustomerChange(index: keyof CustomerInfo, value: any) {
    setCustomer(state => {
      if (!state) {
        return null;
      }

      return {
        ...state,
        customer: {
          ...state.customer,
          [index]: value,
        },
      };
    });
  }

  function handleValidation() {
    setValidation({});

    validate(customer)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/users/${id}`, customer)
      .then(() => {
        history.push('/customers');
      })
      .catch(err => {
        console.error(err);
        setSaving(false);
      });
  }

  function handleRendering() {
    if (loading) {
      return <InsideLoading />;
    }

    if (error) {
      return <span>{error}</span>;
    }

    const options = {
      user: <CustomerUserForm validation={validation} customer={customer} />,
      company: <CustomerCompanyForm validation={validation} customer={customer} />,
    };

    return options[tab];
  }

  return (
    <CustomerProvider value={{ customer, handleUserChange, handleCustomerChange }}>
      {saving && <Loading />}

      <Appbar
        title="Cliente"
        ActionsComponent={<NewCustomerAppbarButtons handleValidation={handleValidation} />}
        Tab={<CustomerTabs tab={tab} handleChange={value => setTab(value)} />}
      />

      <PageHeader backUrl="/customers" title="Cliente" description="Atualização dos dados cadastrais" />

      {handleRendering()}
    </CustomerProvider>
  );
};

export default UpdateCustomer;
