import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Done } from '@mui/icons-material';

type CityActionsProps = {
  handleSubmit(): void;
  loading: boolean;
  saving: boolean;
};

const CityActions: React.FC<CityActionsProps> = ({ handleSubmit, loading, saving }) => {
  return (
    <Tooltip title="Salvar">
      <span>
        <IconButton disabled={saving || loading} color="inherit" onClick={handleSubmit}>
          <Done />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default CityActions;
