import React, { useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import PageHeader from 'components/page-header/PageHeader';
import { useParams } from 'react-router-dom';
import { api } from 'services/api';
import { history } from 'services/history';
import { Grid } from '@mui/material';
import { Customer } from 'types/customer';
import CustomerItem from './CustomerItem';

const CustomerShow: React.FC = () => {
  const [customer, setCustomer] = useState<Customer>({} as Customer);
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/users/${id}`)
      .then(response => {
        setCustomer(response.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <>
      <Appbar title="Cliente" backAction={() => history.push('/customers')} />
      <PageHeader title={`${customer.first_name + ' ' + customer.last_name}`} backUrl="/customers" />
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          <CustomerItem customer={customer} />
        </Grid>
      )}
    </>
  );
};

export default CustomerShow;
