import React, { useReducer, FormEvent, useState } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import UserForm from './UserForm';
import userReducer, { USER_INITIAL_STATE } from '../reducer/reducer';
import { userChange } from '../reducer/actions';
import UserActions from './UserActions';
import * as yup from 'yup';
import { api } from 'services/api';
import { history } from 'services/history';
import Loading from 'components/loading/Loading';
import { useMessaging } from 'providers/messaging';
import PageHeader from 'components/page-header/PageHeader';

export type UserValidation = {
  email?: string;
  name?: string;
  password?: string;
  password_confirmation?: string;
};

const NewUser: React.FC = () => {
  const messaging = useMessaging();
  const [user, dispatch] = useReducer(userReducer, USER_INITIAL_STATE);
  const [validation, setValidation] = useState<UserValidation>({});
  const [loading, setLoading] = useState(false);

  function handleChange(index: string, value: any) {
    dispatch(userChange(index, value));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    const schema = yup.object().shape({
      password: yup.string().min(8, 'A senha deve ter no mínimo 8 caracteres').required('Informe a nova senha'),
      password_confirmation: yup.string().oneOf([yup.ref('password'), undefined], 'Nova senha não confere'),
      name: yup.string().required('O nome é obrigatório'),
      email: yup.string().email('Informe um e-mail válido').required('Informe o email'),
    });

    schema
      .validate(user)
      .then(() => {
        handleSubmit();
        setValidation({});
      })
      .catch(err => {
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setLoading(true);

    api
      .post('/admin-users', user)
      .then(() => {
        messaging.handleOpen('Salvo');
        setLoading(false);
        history.push('/users');
      })
      .catch(err => {
        setLoading(false);
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar o usuário');
      });
  }

  return (
    <>
      <Appbar
        title="Novo usuário"
        ActionsComponent={<UserActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/users')}
      />

      {loading && <Loading />}

      <PageHeader title="Cadastro de usuário" backUrl="/users" />

      <Grid container style={{ flex: 1 }} direction="column">
        <Grid item xs={12} lg={4} xl={4} md={6}>
          <form onSubmit={handleValidation}>
            <UserForm handleChange={handleChange} user={user} validation={validation} />
            <button type="submit" style={{ display: 'none' }} />
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default NewUser;
