import React, { FormEvent, useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { api } from 'services/api';
import BlancaLuzLoanActions from './BlancaLuzLoanActions';
import BlancaLuzLoanForm from '../BlancaLuzLoanForm';
import { useBlancaLuzLoanValidation } from '../validation/blancaLuzLoanValidation';
import { useMessaging } from 'providers/messaging';
import { history } from 'services/history';
import { BlancaLuzLoan } from 'types/blancaLuzLoan';

const intialValue: BlancaLuzLoan = {
  id: 0,
  customer_representative_name: '',
  contract_download_url: '',
  installment_quantity: 0,
  installment_value: 0,
  pix_key: '',
  reseller_id: 0,
  value: 0,
  created_at: '',
};

const BlancaLuzLoanNew: React.FC = () => {
  const [loan, setLoan] = useState<BlancaLuzLoan>(intialValue);
  const [validation, setValidation, validate] = useBlancaLuzLoanValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();

  const handleChange = useCallback((index: keyof BlancaLuzLoan, value: any) => {
    setLoan(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(loan)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/loans', loan)
      .then(() => {
        setSaving(false);
        history.push('/blanca-luz-loan');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar title="BLZ Cred Clientes" ActionsComponent={<BlancaLuzLoanActions handleSubmit={handleValidation} />} />
      <PageHeader title="Novo cliente" backUrl="/blanca-luz-loan" />
      <Grid container>
        <form onSubmit={handleValidation}>
          <BlancaLuzLoanForm handleChange={handleChange} loan={loan} validation={validation} />
        </form>
      </Grid>
    </>
  );
};

export default BlancaLuzLoanNew;
