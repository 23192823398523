import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, Theme } from '@mui/material';
import { WaitingLoanValidation } from './validation/waitingLoanValidation';
import { BlancaLuzLoan } from 'types/blancaLuzLoan';

const useStyles = makeStyles((theme: Theme) => ({
  switch: {
    marginTop: 20,
  },
  container: {
    display: 'flex',
    marginBottom: 200,
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {},
    maxWidth: '400px',
  },
  imageContainer: {
    marginTop: 20,
  },
}));

type WaitingLoanFormProps = {
  loan: BlancaLuzLoan;
  handleChange(index: keyof BlancaLuzLoan, value: any): void;
  validation: WaitingLoanValidation;
};

const WaitingLoanForm: React.FC<WaitingLoanFormProps> = ({ loan, handleChange, validation }) => {
  const classes = useStyles();

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    value: useRef<HTMLInputElement>(null),
    pix_key: useRef<HTMLInputElement>(null),
    installment_quantity: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      <div className={classes.container}>
        <TextField
          inputRef={inputs.name}
          error={!!validation.name}
          helperText={validation.name}
          label="Nome do cliente"
          placeholder="Nome do cliente"
          margin="normal"
          fullWidth
          value={loan.customer_representative_name || ''}
          onChange={e => handleChange('customer_representative_name', e.target.value)}
          autoFocus
        />

        <TextField
          inputRef={inputs.value}
          error={!!validation.value}
          helperText={validation.value}
          label="Valor"
          placeholder="Valor"
          margin="normal"
          fullWidth
          value={loan.value || ''}
          onChange={e => handleChange('value', e.target.value)}
          autoFocus
          type="number"
          inputProps={{
            inputMode: 'decimal',
          }}
        />

        <TextField
          inputRef={inputs.pix_key}
          error={!!validation.pix_key}
          helperText={validation.pix_key}
          label="Chave pix"
          placeholder="Chave pix"
          margin="normal"
          fullWidth
          value={loan.pix_key || ''}
          onChange={e => handleChange('pix_key', e.target.value)}
          autoFocus
        />

        <button type="submit" style={{ display: 'none' }} />
      </div>
    </>
  );
};

export default WaitingLoanForm;
