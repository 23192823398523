import React from 'react';
import { ListItem, Typography } from '@mui/material';
import { City } from 'types/city';
import { history } from 'services/history';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  content: {
    display: 'grid',
    rowGap: '7px',
    marginTop: 10,
    '& > p': {
      display: 'grid',
      gap: '0px',
      gridTemplateColumns: '120px 1fr',
    },
  },
});

type CityItemModuleProps = {
  city: City;
};

const CityItemModule: React.FC<CityItemModuleProps> = ({ city }) => {
  const classes = styles();

  return (
    <ListItem className={classes.container} onClick={() => history.push(`/city/${city.id}`)}>
      <Typography variant="caption" color="primary">
        {city.id}
      </Typography>
      <div className={classes.content}>
        <Typography>
          <span>Nome</span>
          <span>{city.name}</span>
        </Typography>
        <Typography>
          <span>Estado</span>
          <span>{city.state}</span>
        </Typography>
        <Typography>
          <span>Código IBGE</span>
          <span>{city.ibge_code}</span>
        </Typography>
      </div>
    </ListItem>
  );
};

export default CityItemModule;
