import { Done } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

type UserActionsProps = {
  handleSubmit(): void;
};

const UserActions: React.FC<UserActionsProps> = ({ handleSubmit }) => {
  return (
    <>
      <Tooltip title="Salvar">
        <IconButton color="inherit" onClick={handleSubmit}>
          <Done />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default UserActions;
