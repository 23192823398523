import React, { useState } from 'react';
import { Typography } from '@mui/material';
import BlancaLuzInstallmentItemTable from './BlancaLuzInstallmentItemTable';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { BlancaLuzInstallment } from 'types/blancaLuzInstallment';
import { history } from 'services/history';
import { makeStyles } from '@mui/styles';
import InstallmentsListMenu from '../InstallmentsListMenu';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type BlancaLuzInstallmentListTableProps = {
  installments: BlancaLuzInstallment[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const BlancaLuzInstallmentListTable: React.FC<BlancaLuzInstallmentListTableProps> = ({
  installments,
  handleSort,
  orderedIndex,
}) => {
  const classes = useStyles();
  const { tableTemplate } = useTable();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <InstallmentsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <div
              className={classes.headerItem}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {installments.map(installment => (
            <TableRow key={installment.id} onClick={() => history.push(`/blanca-luz-installment/${installment.id}`)}>
              <BlancaLuzInstallmentItemTable installment={installment} setAnchorEl={setAnchorEl} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default BlancaLuzInstallmentListTable;
