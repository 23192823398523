import React, { useState } from 'react';
import { useApp } from 'hooks/useApp';
import SidebarItem from './SidebarItem';
import { makeStyles } from '@mui/styles';
import { SIDEBAR_WIDTH } from 'constants/constants';
import { Avatar, Drawer, Theme, Typography } from '@mui/material';
import ImageSelector from 'components/image-selector/ImageSelector';
import { useSelector } from 'store/selector';
import { SidebarProvider } from './hook/useSidebar';
import BlzCred from './collapsible/BlzCred';
import UserControlSidebar from './user/UserControlSidebar';
import Settings from './collapsible/Settings';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: SIDEBAR_WIDTH,
    '@media print': {
      display: 'none',
    },
  },
  drawerHeader: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
  },
  avatar: {
    width: 40,
    height: 40,
    border: `2px solid ${theme.palette.secondary.light}`,
  },
  logout: {
    position: 'absolute',
    bottom: 0,
  },
  account: {
    minHeight: 90,
    marginTop: 30,
  },
  content: {
    transition: 'opacity 0.4s ease',
    opacity: 1,
  },
  sidebarItemAvatar: {
    marginBottom: 10,
  },
}));

export type Collapsible = {
  blancaLuzRegistration: boolean;
  blancaLuzLoan: boolean;
  settings: boolean;
};

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const [shownUserControl, setShownUserControl] = useState(false);
  const app = useApp();
  const user = useSelector(state => state.user);
  const [imageManager, setImageManager] = useState(false);
  const [collapsible, setCollapsible] = useState<Collapsible>({
    blancaLuzRegistration: false,
    blancaLuzLoan: false,
    settings: false,
  });

  function handleCollapseClick(index: keyof Collapsible, closeOthers = true): void {
    if (closeOthers) {
      const keys = Object.keys(collapsible);

      let otherValues: Collapsible = {} as Collapsible;
      keys.forEach(key => {
        otherValues = {
          ...otherValues,
          [key]: false,
        };
      });

      setCollapsible({
        ...otherValues,
        [index]: !collapsible[index],
      });
      return;
    }

    setCollapsible({
      ...collapsible,
      [index]: !collapsible[index],
    });
  }

  return (
    <SidebarProvider value={{ handleCollapseClick, collapsible, setImageManager, setShownUserControl }}>
      <Drawer
        variant={app.isMobile || app.windowWidth < 1280 ? 'temporary' : 'persistent'}
        anchor="left"
        classes={{ paper: classes.drawerPaper }}
        open={app.isOpenedMenu}
        onClose={app.handleOpenMenu}
      >
        {imageManager && <ImageSelector onExited={() => setImageManager(false)} />}
        <UserControlSidebar show={shownUserControl} handleClose={() => setShownUserControl(false)} />

        <div className={classes.content}>
          <div className={classes.drawerHeader}>
            <Typography variant="h6">BLZ Cred</Typography>
          </div>

          {user && (
            <>
              <BlzCred />

              <Settings />
            </>
          )}
        </div>
        <SidebarItem
          className={classes.sidebarItemAvatar}
          to="/account"
          icon={
            user ? (
              <>
                {user.image ? (
                  <Avatar className={classes.avatar} src={user.image.imageUrl} />
                ) : (
                  <Avatar className={classes.avatar}>{user.name[0]}</Avatar>
                )}
              </>
            ) : (
              <Avatar className={classes.avatar}>U</Avatar>
            )
          }
          text={user ? user.name : 'Carregando...'}
        />
      </Drawer>
    </SidebarProvider>
  );
};

export default Sidebar;
