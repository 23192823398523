import { TableTemplate } from 'types/tableTemplate';

export const cityTableTemplate: TableTemplate[] = [
  {
    id: 'name',
    description: 'Nome',
    originalId: 'name',
    width: 150,
  },
  {
    id: 'state',
    description: 'Estado',
    originalId: 'state',
    width: 70,
  },
  {
    id: 'ibge_code',
    description: 'IBGE',
    originalId: 'ibge_code',
    dataType: 'number',
    width: 80,
  },
  {
    id: 'formattedActive',
    description: 'ATIVO',
    originalId: 'active',
    width: 60,
  },
];
