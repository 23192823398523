import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { BlancaLuzInstallment } from 'types/blancaLuzInstallment';
import { useBlancaLuzInstallments } from 'pages/blanca-luz/blanca-luz-installments/hooks/useBlancaLuzInstallments';
import { MoreHoriz } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type BlancaLuzInstallmentItemTableProps = {
  installment: BlancaLuzInstallment;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const BlancaLuzInstallmentItemTable: React.FC<BlancaLuzInstallmentItemTableProps> = ({ installment, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedInstallment } = useBlancaLuzInstallments();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedInstallment(installment);
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{installment[item.id]}</Typography>
            </div>
          )
        )}
    </>
  );
};

export default BlancaLuzInstallmentItemTable;
