import Error404 from 'components/error/Error404';
import Account from 'pages/account/Account';
import Forgot from 'pages/forgot/Forgot';
import Home from 'pages/home/Home';
import Login from 'pages/login/Login';
import PasswordReset from 'pages/password-reset/PasswordReset';
import Settings from 'pages/settings/Settings';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import BlancaLuzInstallments from 'pages/blanca-luz/blanca-luz-installments/BlancaLuzInstallments';
import BlancaLuzInstallmentNew from 'pages/blanca-luz/blanca-luz-installments/registration/new/BlancaLuzInstallmentNew';
import BlancaLuzInstallmentUpdate from 'pages/blanca-luz/blanca-luz-installments/registration/update/BlancaLuzInstallmentUpdate';
import BlancaLuzLoanPage from 'pages/blanca-luz/approved/BlancaLuzLoan';
import BlancaLuzLoanNew from 'pages/blanca-luz/approved/registration/new/BlancaLuzLoanNew';
import BlancaLuzLoanUpdate from 'pages/blanca-luz/approved/registration/update/BlancaLuzLoanUpdate';
import WaitingLoanPage from 'pages/blanca-luz/waiting/WaitingLoan';
import WaitingLoanUpdate from 'pages/blanca-luz/waiting/registration/update/WaitingLoanUpdate';
import FailedLoanPage from 'pages/blanca-luz/failed/FailedLoan';
import FailedLoanUpdate from 'pages/blanca-luz/failed/registration/update/FailedLoanUpdate';
import City from 'pages/city/City';
import CityNew from 'pages/city/registration/new/CityNew';
import CityUpdate from 'pages/city/registration/update/CityUpdate';
import Users from 'components/users/Users';
import NewUser from 'components/users/new/NewUser';
import EditUser from 'components/users/edit/EditUser';
import Banners from 'pages/banners/Banners';
import BannerNew from 'pages/banners/registration/new/BannerNew';
import BannerUpdate from 'pages/banners/registration/update/BannerUpdate';
import Customers from 'pages/customers/Customers';
import CustomerShow from 'pages/customers/show/CustomerShow';
import UpdateCustomer from 'pages/customers/registration/update/UpdateCustomer';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Error404 />} />

      <Route path="/" element={<ProtectedRoute element={<Home />} />} />

      <Route path="/login" element={<PublicRoute element={<Login />} />} />

      <Route path="/forgot" element={<PublicRoute element={<Forgot />} />} />

      <Route path="/password-reset/:token" element={<PublicRoute element={<PasswordReset />} />} />

      <Route path="/settings" element={<ProtectedRoute element={<Settings />} />} />

      <Route path="/account" element={<ProtectedRoute element={<Account />} />} />

      <Route path="/banners" element={<ProtectedRoute element={<Banners />} />} />
      <Route path="/banner" element={<ProtectedRoute element={<BannerNew />} />} />
      <Route path="/banner/:id" element={<ProtectedRoute element={<BannerUpdate />} />} />

      <Route path="/cities" element={<ProtectedRoute element={<City />} />} />
      <Route path="/city/new" element={<ProtectedRoute element={<CityNew />} />} />
      <Route path="/city/:id" element={<ProtectedRoute element={<CityUpdate />} />} />

      <Route element={<ProtectedRoute element={<BlancaLuzInstallments />} />} path="/blanca-luz-installments" />
      <Route element={<ProtectedRoute element={<BlancaLuzInstallmentNew />} />} path="/blanca-luz-installment" />
      <Route element={<ProtectedRoute element={<BlancaLuzInstallmentUpdate />} />} path="/blanca-luz-installment/:id" />

      <Route element={<ProtectedRoute element={<BlancaLuzLoanPage />} />} path="/blanca-luz-loan" />
      <Route element={<ProtectedRoute element={<BlancaLuzLoanNew />} />} path="/blanca-luz-loan/new" />
      <Route element={<ProtectedRoute element={<BlancaLuzLoanUpdate />} />} path="/blanca-luz-loan/:id" />

      <Route element={<ProtectedRoute element={<WaitingLoanPage />} />} path="/waiting-loan" />
      <Route element={<ProtectedRoute element={<WaitingLoanUpdate />} />} path="/waiting-loan/:id" />

      <Route element={<ProtectedRoute element={<FailedLoanPage />} />} path="/failed-loan" />
      <Route element={<ProtectedRoute element={<FailedLoanUpdate />} />} path="/failed-loan/:id" />

      <Route element={<ProtectedRoute element={<Users />} />} path="/users" />
      <Route element={<ProtectedRoute element={<NewUser />} />} path="/user" />
      <Route element={<ProtectedRoute element={<EditUser />} />} path="/user/:id" />

      <Route element={<ProtectedRoute element={<Customers />} />} path="/customers" />
      <Route element={<ProtectedRoute element={<CustomerShow />} />} path="/customer/:id" />
      <Route element={<ProtectedRoute element={<UpdateCustomer />} />} path="/customers/:id" />
    </Routes>
  );
};

export default AppRoutes;
