import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, InputAdornment } from '@mui/material';
import { Person, Visibility, VisibilityOff } from '@mui/icons-material';
import { PasswordResetValidation, ResetData } from './PasswordReset';

const useStyles = makeStyles({
  icon: {
    cursor: 'pointer',
  },
});

type LoginFormProps = {
  reset: ResetData;
  handleChange(index: 'email' | 'password' | 'password_confirmation', value: string): void;
  validation: PasswordResetValidation;
};

const PasswordResetForm: React.FC<LoginFormProps> = ({ reset, handleChange, validation }) => {
  const classes = useStyles();
  const [passVisibility, setPassVisibility] = useState(true);
  const [passConfirmVisibility, setPassConfirmVisibility] = useState(true);

  const inputs = {
    email: useRef<HTMLInputElement>(null),
    password: useRef<HTMLInputElement>(null),
    password_confirmation: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  function handlePasswordVisibility() {
    setPassVisibility(!passVisibility);
  }

  function handlePasswordConfirmVisibility() {
    setPassConfirmVisibility(!passConfirmVisibility);
  }

  return (
    <>
      <TextField
        inputRef={inputs.email}
        error={!!validation.email}
        helperText={validation.email}
        label="E-mail"
        placeholder="Seu e-mail"
        margin="normal"
        onChange={e => handleChange('email', e.target.value)}
        value={reset.email}
        autoComplete="email"
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Person color="primary" />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        inputRef={inputs.password}
        error={!!validation.password}
        helperText={validation.password}
        label="Nova senha"
        placeholder="Sua nova senha"
        margin="normal"
        onChange={e => handleChange('password', e.target.value)}
        value={reset.password}
        type={passVisibility ? 'password' : 'text'}
        autoComplete="current-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {passVisibility ? (
                <Visibility className={classes.icon} onClick={handlePasswordVisibility} color="primary" />
              ) : (
                <VisibilityOff className={classes.icon} onClick={handlePasswordVisibility} color="primary" />
              )}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        inputRef={inputs.password_confirmation}
        error={!!validation.password_confirmation}
        helperText={validation.password_confirmation}
        label="Nova senha"
        placeholder="Repita a nova senha"
        margin="normal"
        onChange={e => handleChange('password_confirmation', e.target.value)}
        value={reset.password_confirmation}
        type={passConfirmVisibility ? 'password' : 'text'}
        autoComplete="password-confirm"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {passConfirmVisibility ? (
                <Visibility className={classes.icon} onClick={handlePasswordConfirmVisibility} color="primary" />
              ) : (
                <VisibilityOff className={classes.icon} onClick={handlePasswordConfirmVisibility} color="primary" />
              )}
            </InputAdornment>
          ),
        }}
      />
      <button type="submit" style={{ display: 'none' }} />
    </>
  );
};

export default PasswordResetForm;
