import React from 'react';
import { QueryParams } from 'types/queryParams';
import Dialog, { DialogConsumer } from 'components/dialogs/Dialog';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import MobileSearchActions from './MobileSearchActions';

type MobileSearchProps = {
  onExited(): void;
  handleQueryParamsChange(index: string, value: any): void;
  queryParams: QueryParams;
  loading: boolean;
};

const MobileSearch: React.FC<MobileSearchProps> = ({ onExited, handleQueryParamsChange, queryParams, loading }) => {
  return (
    <Dialog
      maxWidth="sm"
      onExited={onExited}
      title="Filtrar"
      height="60vh"
      ComponentActions={<MobileSearchActions handleClose={onExited} />}
    >
      <DialogConsumer>
        {({ handleClose }) => (
          <Box display="flex" flexDirection="column" gap="10px">
            {queryParams.initial_date && queryParams.final_date && (
              <>
                <KeyboardDatePicker
                  label="Data inicial"
                  value={queryParams.initial_date || null}
                  onChange={date => handleQueryParamsChange('initial_date', date)}
                />

                <KeyboardDatePicker
                  label="Data Final"
                  value={queryParams.final_date || null}
                  onChange={date => handleQueryParamsChange('final_date', date)}
                />
              </>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  disabled={loading}
                  checked={queryParams.is_active}
                  onChange={e => handleQueryParamsChange('is_active', e.target.checked)}
                  color="primary"
                />
              }
              label="Ativos"
            />
          </Box>
        )}
      </DialogConsumer>
    </Dialog>
  );
};

export default MobileSearch;
