import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { City } from 'types/city';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type CityItemTableProps = {
  city: City;
};

const CityItemTable: React.FC<CityItemTableProps> = ({ city }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{city[item.id]}</Typography>
            </div>
          )
        )}
    </>
  );
};

export default CityItemTable;
