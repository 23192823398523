import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import BannerItemModule from './BannerItemModule';
import { Banner } from 'types/banner';
import { Grid } from '@mui/material';

type BannerListModuleProps = {
  banners: Banner[];
};

const BannerListModule: React.FC<BannerListModuleProps> = ({ banners }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {banners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(banner => (
            <BannerItemModule key={banner.id} banner={banner} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default BannerListModule;
