import React, { useCallback, useEffect, useState } from 'react';
import { api } from 'services/api';
import { ptBR } from 'date-fns/locale';
import useTableOrder from 'hooks/tableOrder';
import Appbar from 'components/appbar/Appbar';
import { Button, Theme } from '@mui/material';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { format, parseISO, subDays } from 'date-fns';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableContainer from 'components/table/TableContainer';
import BlancaLuzInstallmentsActions from './BlancaLuzInstallmentsActions';
import BlancaLuzInstallmentsFilterBox from './BlancaLuzInstallmentsFilterBox';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { BlancaLuzInstallmentsProvider } from './hooks/useBlancaLuzInstallments';
import BlancaLuzInstallmentListTable from './list/table/BlancaLuzInstallmentListTable';
import { blancaLuzInstallmentsTableTemplate } from './blancaLuzInstallmentsTableTemplate';
import BlancaLuzInstallmentListModule from './list/module/BlancaLuzInstallmentListModule';
import MobileSearch from 'components/search/MobileSearch';
import ApiPagination from 'components/pagination/ApiPagination';
import { useApp } from 'hooks/useApp';
import { history } from 'services/history';
import { BlancaLuzInstallment } from 'types/blancaLuzInstallment';
import { numberFormat } from 'helpers/numberFormat';
import { makeStyles } from '@mui/styles';
import Loading from 'components/loading/Loading';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

export type BlancaLuzInstallmentsQueryParams = {
  initial_date: Date;
  final_date: Date;
  is_active: boolean;
  installment: string;
};

const queryParamsInitialValue: BlancaLuzInstallmentsQueryParams = {
  initial_date: subDays(new Date(), 365),
  final_date: new Date(),
  is_active: true,
  installment: '',
};

let timer: NodeJS.Timeout;

const BlancaLuzInstallments: React.FC = () => {
  const app = useApp();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(96);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [orderedIndex, sort] = useTableOrder();
  const [searchDialog, setSearchDialog] = useState(false);
  const [installments, setInstallments] = useState<BlancaLuzInstallment[]>([]);
  const [filtered, setFiltered] = useState<BlancaLuzInstallment[]>([]);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [selectedInstallment, setSelectedInstallment] = useState<null | BlancaLuzInstallment>(null);
  const [queryParams, setQueryParams] = useState<BlancaLuzInstallmentsQueryParams>(queryParamsInitialValue);
  const [saving, setSaving] = useState(false);

  const fetchBanners = useCallback(
    (query?: BlancaLuzInstallmentsQueryParams) => {
      setLoading(true);
      api
        .get('/installments', {
          params: { ...query, is_active: query?.is_active ? 1 : 0, page: page + 1, rows },
        })
        .then(_response => {
          const response = _response.data;
          setInstallments(
            response.data.map((item: BlancaLuzInstallment) => {
              item.formattedCreatedAt = format(parseISO(item.created_at), 'P', { locale: ptBR });
              item.formattedIsActive = item.is_active ? 'Sim' : 'Não';
              item.formattedFee = numberFormat(item.fee, 3);
              item.formattedDailyIof = numberFormat(item.daily_iof, item.daily_iof.toString().length - 2);
              item.formattedForSimplesNacional = item.for_simples_nacional ? 'Sim' : 'Não';
              item.formattedAdditionalIof = numberFormat(
                item.additional_iof,
                item.additional_iof.toString().length - 2
              );
              item.formattedFactor = numberFormat(item.factor, 5);
              return item;
            })
          );
          setTotal(response.total);
        })
        .catch(err => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    },
    [page, rows]
  );

  useEffect(() => {
    setFiltered(installments);
  }, [installments]);

  useEffect(() => {
    fetchBanners(queryParamsInitialValue);
  }, [fetchBanners]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleQueryParamsChange(index: keyof BlancaLuzInstallmentsQueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));

    const query = {
      ...queryParams,
      [index]: value,
    };

    clearTimeout(timer);

    if (index !== 'installment') {
      fetchBanners(query);
      return;
    }

    timer = setTimeout(() => fetchBanners(query), 500);
  }

  function handleDelete() {
    if (!selectedInstallment?.id) {
      return;
    }

    setSaving(true);

    api
      .delete(`/installments/${selectedInstallment.id}`)
      .then(() => {
        setInstallments(state => state.filter(item => item.id !== selectedInstallment.id));
      })
      .catch(error => console.error(error))
      .finally(() => setSaving(false));
  }

  return (
    <BlancaLuzInstallmentsProvider value={{ selectedInstallment, setSelectedInstallment, handleDelete }}>
      {saving && <Loading />}

      {searchDialog && (
        <MobileSearch
          loading={loading}
          handleQueryParamsChange={handleQueryParamsChange}
          onExited={() => setSearchDialog(false)}
          queryParams={queryParams}
        />
      )}

      <Appbar
        title="BLZ Cred Parcelas"
        ActionsComponent={<BlancaLuzInstallmentsActions openDialog={() => setSearchDialog(true)} />}
      />
      <PageHeaderActions
        title="BLZ Cred Parcelas"
        description="Gestão das parcelas"
        ActionComponent={
          <>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => history.push('/blanca-luz-installment')}
            >
              Adicionar
            </Button>
          </>
        }
      />
      <TableContainer tableTemplate={blancaLuzInstallmentsTableTemplate}>
        <BlancaLuzInstallmentsFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          handleQueryParamsChange={handleQueryParamsChange}
          queryParams={queryParams}
        />

        <PaginationProvider>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhum parcela cadastrada" />
          ) : (
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <BlancaLuzInstallmentListTable
                  installments={filtered}
                  handleSort={handleSort}
                  orderedIndex={orderedIndex}
                />
              ) : (
                displayMode === 'module' && <BlancaLuzInstallmentListModule installments={filtered} />
              )}
            </div>
          )}

          <ApiPagination
            onChangePage={value => setPage(value)}
            onChangeRowsPerPage={value => setRows(value)}
            count={total}
          />
        </PaginationProvider>
      </TableContainer>
    </BlancaLuzInstallmentsProvider>
  );
};

export default BlancaLuzInstallments;
