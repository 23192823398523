import React, { useEffect, useRef } from 'react';
import { TextField, styled } from '@mui/material';
import { User } from '../reducer/types';
import { UserValidation } from './EditUser';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 600,
  marginTop: 30,
});

type UserFormProps = {
  user: User;
  handleChange(index: string, value: string): void;
  validation: UserValidation;
};

const UserForm: React.FC<UserFormProps> = ({ user, handleChange, validation }) => {
  const inputs = {
    username: useRef<HTMLInputElement>(null),
    name: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <Container>
      <TextField
        inputRef={inputs.email}
        error={!!validation.email}
        helperText={validation.email}
        label="E-mail"
        placeholder="Digite o e-mail"
        margin="normal"
        fullWidth
        value={user.email}
        onChange={e => handleChange('email', e.target.value)}
        autoComplete="none"
        disabled
      />

      <TextField
        inputRef={inputs.name}
        error={!!validation.name}
        helperText={validation.name}
        label="Nome"
        placeholder="Digite o nome"
        margin="normal"
        fullWidth
        value={user.name}
        onChange={e => handleChange('name', e.target.value)}
      />
    </Container>
  );
};

export default UserForm;
