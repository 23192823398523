import { User } from 'types/user';
import { UserActionsType, SET_USER } from './types';

export const INITIAL_STATE: User | null = null;

export default function userReducer(state = INITIAL_STATE, action: UserActionsType): User | null {
  switch (action.type) {
    case SET_USER: {
      return action.user;
    }

    default: {
      return state;
    }
  }
}
