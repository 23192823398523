import { Add } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';
import React from 'react';
import { history } from 'services/history';

const Container = styled('div')(props => ({
  display: 'none',
  [props.theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

const UserActions: React.FC = () => {
  return (
    <Container>
      <IconButton color="inherit" onClick={() => history.push('/user')}>
        <Add />
      </IconButton>
    </Container>
  );
};

export default UserActions;
