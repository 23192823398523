import React, { useState, FormEvent, useEffect } from 'react';
import LoginForm from './LoginForm';
import { makeStyles } from '@mui/styles';
import { useMessaging } from 'providers/messaging';
import { Link, useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Theme, Typography } from '@mui/material';
import { useAuth } from 'providers/auth';
import { useLoginValidation } from './validation/loginValidation';
import { history } from 'services/history';
import { ArrowRightAlt } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      backgroundColor: '#fff',
    },
  },
  content: {
    display: 'flex',
    padding: 30,
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    minWidth: 400,
    margin: 20,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
      height: '100%',
      padding: 10,
    },
  },
  header: {
    padding: '0 0 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h5': {
      marginTop: 20,
    },
  },
  actions: {
    display: 'flex',
    padding: '50px 0',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '& > .forgot': {
      marginTop: 30,
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  logo: {
    width: 90,
    height: 90,
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  logoWrapper: {
    gap: 10,
    height: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  a: {
    padding: 10,
  },
}));

export type LoginValidation = {
  email?: string;
  password?: string;
};

const Login: React.FC = () => {
  const auth = useAuth();
  const classes = useStyles();
  const navigate = useNavigate();
  const messaging = useMessaging();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [validation, setValidation, validate] = useLoginValidation();

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push('/');
    }
  }, [auth]);

  function handleSubmit() {
    setLoading(true);

    auth
      .login(email, password)
      .then(() => {
        navigate('/');
      })
      .catch(err => {
        setLoading(false);

        if (err?.response.status === 401) {
          setValidation({
            email: 'E-mail está correto?',
            password: 'Senha está correta?',
          });
          return;
        }

        if (err.response) {
          messaging.handleOpen('Não foi possível fazer login');
          console.log(err.response);
          return;
        }

        messaging.handleOpen('Não foi possível conectar ao servidor');
      });
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate({ email, password })
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {loading && (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        )}
        <div className={classes.header}>
          <div className={classes.logoWrapper}>
            <a className={classes.a} href={process.env.PUBLIC_URL}>
              <img
                className={classes.logo}
                src="https://h2i.s3-sa-east-1.amazonaws.com/upload/images/logo.png"
                alt="H2I logo"
              />
            </a>
            <ArrowRightAlt fontSize="large" color="primary" />
            <a className={classes.a} href={process.env.PUBLIC_URL}>
              <img
                className={classes.logo}
                src="https://h2i-blzcred.s3.sa-east-1.amazonaws.com/upload/images/18123720230405642dba1559000.png"
                alt="BLZ Cred logo"
              />
            </a>
          </div>
          <Typography align="center" variant="h5">
            Login
          </Typography>
        </div>
        <form onSubmit={handleValidation}>
          <LoginForm
            email={email}
            password={password}
            setPassword={setPassword}
            setEmail={setEmail}
            validation={validation}
          />
        </form>
        <div className={classes.actions}>
          <Button
            onClick={() => handleValidation(undefined)}
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            disabled={loading}
          >
            Entrar
          </Button>
          <div className="forgot">
            <Link to="/forgot">Esqueci minha senha</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
