import React from 'react';
import { Typography, ListItemButton, styled } from '@mui/material';
import { history } from 'services/history';
import { AdminUser } from 'types/admin-user';

type UserItemProps = {
  user: AdminUser;
};

const ListItemButtonStyled = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  '& > .data': {
    display: 'flex',
    flexDirection: 'column',
  },
});

const UserItem: React.FC<UserItemProps> = ({ user }) => {
  function handleClick(id: string) {
    history.push(`/user/${id}`);
  }

  return (
    <ListItemButtonStyled onClick={() => handleClick(user.id)}>
      <div className="data">
        <Typography variant="h6">{user.name}</Typography>
        <Typography gutterBottom variant="caption" color="textSecondary">
          {user.email}
        </Typography>
      </div>
    </ListItemButtonStyled>
  );
};

export default UserItem;
