import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { BlancaLuzLoan } from 'types/blancaLuzLoan';

type BlancaLuzLoanContextValue = {
  selectedLoan: BlancaLuzLoan | null;
  setSelectedLoan: Dispatch<SetStateAction<BlancaLuzLoan | null>>;
};

const BlancaLuzLoanContext = createContext<BlancaLuzLoanContextValue>({} as BlancaLuzLoanContextValue);
export const BlancaLuzLoanProvider = BlancaLuzLoanContext.Provider;

export function useBlancaLuzLoan(): BlancaLuzLoanContextValue {
  const context = useContext(BlancaLuzLoanContext);
  return context;
}
