import React from 'react';
import List from 'components/list/List';
import { City } from 'types/city';
import CityItemModule from './CityItemModule';

type CityListModuleProps = {
  cities: City[];
};

const CityListModule: React.FC<CityListModuleProps> = ({ cities }) => {
  return (
    <List>
      {cities.map(city => (
        <CityItemModule key={city.id} city={city} />
      ))}
    </List>
  );
};

export default CityListModule;
