import React, { FormEvent, useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { api } from 'services/api';
import { useMessaging } from 'providers/messaging';
import { history } from 'services/history';
import { City } from 'types/city';
import { useCityValidation } from '../validation/cityValidation';
import CityActions from './CityActions';
import CityForm from '../CityForm';

const intialValue: City = {
  id: '',
  name: '',
  ibge_code: '',
  state: '',
  active: true,
};

const CityNew: React.FC = () => {
  const [city, setCity] = useState<City>(intialValue);
  const [validation, setValidation, validate] = useCityValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();

  const handleChange = useCallback((index: keyof City, value: any) => {
    setCity(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(city)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/cities', city)
      .then(() => {
        setSaving(false);
        history.push('/cities');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar title="Municípios" ActionsComponent={<CityActions handleSubmit={handleValidation} />} />
      <PageHeader title="Nova cidade" backUrl="/cities" />
      <Grid container>
        <form onSubmit={handleValidation}>
          <CityForm handleChange={handleChange} city={city} validation={validation} />
        </form>
      </Grid>
    </>
  );
};

export default CityNew;
