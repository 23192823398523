import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { BlancaLuzInstallment } from 'types/blancaLuzInstallment';

type BlancaLuzInstallmentsContextValue = {
  selectedInstallment: BlancaLuzInstallment | null;
  setSelectedInstallment: Dispatch<SetStateAction<BlancaLuzInstallment | null>>;
  handleDelete(): void;
};

const BlancaLuzInstallmentsContext = createContext<BlancaLuzInstallmentsContextValue>(
  {} as BlancaLuzInstallmentsContextValue
);
export const BlancaLuzInstallmentsProvider = BlancaLuzInstallmentsContext.Provider;

export function useBlancaLuzInstallments(): BlancaLuzInstallmentsContextValue {
  const context = useContext(BlancaLuzInstallmentsContext);
  return context;
}
